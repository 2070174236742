import keyMirror from 'key-mirror';

export const actionTypes = keyMirror({
  GET_ACCOUNTS_ASYNC_START: null,
  GET_ACCOUNTS_ASYNC_SUCCESS: null,
  GET_ACCOUNTS_ASYNC_ERROR: null,
  UPDATE_ACCOUNT_ASYNC_START: null,
  UPDATE_ACCOUNT_ASYNC_SUCCESS: null,
  UPDATE_ACCOUNT_ASYNC_ERROR: null,
});

export default {
  getAccounts: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_ACCOUNTS_ASYNC_START });
    return api.Account.getAccounts().then(
      payload => dispatch({ type: actionTypes.GET_ACCOUNTS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_ACCOUNTS_ASYNC_ERROR, error }),
    );
  },
  updateAccount: (id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_ACCOUNT_ASYNC_START });
    return api.Account.updateAccount(id, data).then(
      payload => dispatch({ type: actionTypes.UPDATE_ACCOUNT_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.UPDATE_ACCOUNT_ASYNC_ERROR, error }),
    );
  },
};
