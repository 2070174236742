import keyMirror from 'key-mirror';

import snackbarActions from './snackbar';

export const actionTypes = keyMirror({
  TOGGLE_IMPORT_POPOVER: null,
  PREPARE_IMPORT_ASYNC_START: null,
  PREPARE_IMPORT_ASYNC_SUCCESS: null,
  PREPARE_IMPORT_ASYNC_ERROR: null,
  LAUNCH_IMPORT_ASYNC_START: null,
  LAUNCH_IMPORT_ASYNC_SUCCESS: null,
  LAUNCH_IMPORT_ASYNC_ERROR: null,
  TOGGLE_LAUNCH_IMPORT_DIALOG: null,
  TOGGLE_RELOAD_IMPORT_DIALOG: null,
  BULK_UPDATE_ROWS: null,
  SET_SELECTED_IDS: null,
  CHANGE_STEP: null,
  CHANGE_FILES: null,
  CHANGE_LOCATION_CODE: null,
  CHANGE_IMPORT_TYPE: null,
  TOGGLE_LOADING: null,
  UPDATE_ROW_ASYNC_START: null,
  UPDATE_ROW_ASYNC_SUCCESS: null,
  UPDATE_ROW_ASYNC_ERROR: null,
  START_OCR_IMPORT: null,
  OCR_POOLING_TICK: null,
  OCR_POOLING_FINISHED: null,
  OCR_POOLING_ERROR: null,
  CHANGE_SORTING: null,
  TERMINATE_RUNNING_OCR_STARTED: null,
  TERMINATE_RUNNING_OCR_SUCCESS: null,
  TERMINATE_RUNNING_OCR_ERROR: null,
  PARSE_OCR_IMPORTS_ASYNC_START: null,
  PARSE_OCR_IMPORTS_ASYNC_SUCCESS: null,
  PARSE_OCR_IMPORTS_ASYNC_ERROR: null,
  SET_FILTERS: null,
  SET_ROWS_PER_PAGE: null,
  CHANGE_PAGE: null,
});

const sleep = ms => new Promise(r => setTimeout(r, ms));

const retryOperation = (operation, cb, delay = 10000, maxRetries = 10) => {
  return new Promise((resolve, reject) => {
    return operation()
      .then(resolve)
      .catch(reason => {
        cb(reason);
        if (maxRetries > 0) {
          return sleep(delay)
            .then(retryOperation.bind(null, operation, cb, delay, maxRetries - 1))
            .then(resolve)
            .catch(reject);
        }
        return reject(reason);
      });
  });
};

export default {
  setFilters: filters => dispatch => dispatch({ type: actionTypes.SET_FILTERS, filters }),
  changeImportType: importType => dispatch =>
    dispatch({ type: actionTypes.CHANGE_IMPORT_TYPE, importType }),
  changeLocationCode: code => dispatch =>
    dispatch({ type: actionTypes.CHANGE_LOCATION_CODE, code }),
  changeFiles: files => dispatch => dispatch({ type: actionTypes.CHANGE_FILES, files }),
  changeStep: nextStep => dispatch => dispatch({ type: actionTypes.CHANGE_STEP, nextStep }),
  toggleImportPopover: () => dispatch =>
    dispatch({
      type: actionTypes.TOGGLE_IMPORT_POPOVER,
    }),

  parseOCRImports: () => (dispatch, getState, api) => {
    dispatch({
      type: actionTypes.PARSE_OCR_IMPORTS_ASYNC_START,
    });

    return api.Import.parseOCRImports().then(
      () => {
        dispatch(snackbarActions.openSnackbar('import.ocr_parse_results.success_text', 'success'));
        return dispatch({ type: actionTypes.PARSE_OCR_IMPORTS_ASYNC_SUCCESS });
      },
      ({ error }) => {
        dispatch(snackbarActions.openSnackbar(error));
        return dispatch({ type: actionTypes.PARSE_OCR_IMPORTS_ASYNC_ERROR });
      },
    );
  },

  prepareImport: () => (dispatch, getState, api) => {
    const {
      import: { importType, filters, limit, offset, sorting },
    } = getState();

    const params = { importType, ...filters, limit, offset };
    if (sorting?.column) {
      params.orderBy = sorting.column === 'lead_source' ? 'location' : sorting.column;
      params.orderMethod = sorting.order;
    }

    dispatch({
      type: actionTypes.PREPARE_IMPORT_ASYNC_START,
      importType,
    });

    return api.Import.prepareImport(params).then(
      payload => dispatch({ type: actionTypes.PREPARE_IMPORT_ASYNC_SUCCESS, payload, importType }),
      ({ error }) => {
        dispatch(snackbarActions.openSnackbar(error));
        dispatch({ type: actionTypes.PREPARE_IMPORT_ASYNC_ERROR });
      },
    );
  },

  updateRow: data => (dispatch, getState, api) => {
    const {
      import: { importType },
    } = getState();

    dispatch({ type: actionTypes.UPDATE_ROW_ASYNC_START });

    return api.Import.updateRecord(data, { importType }).then(
      payload => dispatch({ type: actionTypes.UPDATE_ROW_ASYNC_SUCCESS, payload }),
      ({ error }) => {
        dispatch({ type: actionTypes.UPDATE_ROW_ASYNC_ERROR });
        dispatch(snackbarActions.openSnackbar(error));
      },
    );
  },

  startOCRImport: () => dispatch => dispatch({ type: actionTypes.START_OCR_IMPORT }),

  toggleLoadingState: isLoading => dispatch =>
    dispatch({ type: actionTypes.TOGGLE_LOADING, isLoading }),

  launchImport: () => (dispatch, getState, api) => {
    dispatch({
      type: actionTypes.LAUNCH_IMPORT_ASYNC_START,
    });

    const {
      import: { selectedIds, data, importType },
    } = getState();

    const dataForImport = data.filter(({ id }) => selectedIds.includes(id));

    return api.Import.launchImport(dataForImport, { importType }).then(
      payload => {
        const { errors, updatedN } = payload;
        if (updatedN) {
          dispatch({ type: actionTypes.LAUNCH_IMPORT_ASYNC_SUCCESS });
          dispatch(
            snackbarActions.openSnackbar('import.snackbar.import_success', 'success', {
              n_rows: updatedN,
            }),
          );
        } else {
          dispatch({ type: actionTypes.LAUNCH_IMPORT_ASYNC_ERROR });
          dispatch(snackbarActions.openSnackbar(errors));
        }
      },
      ({ error }) => {
        dispatch({ type: actionTypes.LAUNCH_IMPORT_ASYNC_ERROR });
        dispatch(snackbarActions.openSnackbar(error));
      },
    );
  },

  updateRowTemp: row => dispatch => dispatch({ type: actionTypes.UPDATE_ROW, row }),

  bulkUpdateRows: data => dispatch => dispatch({ type: actionTypes.BULK_UPDATE_ROWS, data }),

  toggleLaunchImportDialog: flag => dispatch =>
    dispatch({ type: actionTypes.TOGGLE_LAUNCH_IMPORT_DIALOG, flag }),

  toggleReloadImportDialog: flag => dispatch =>
    dispatch({ type: actionTypes.TOGGLE_RELOAD_IMPORT_DIALOG, flag }),

  setSelectedIds: ids => dispatch => dispatch({ type: actionTypes.SET_SELECTED_IDS, ids }),

  startPoolingLastOCR: () => (dispatch, getState, api) => {
    const operation = async () => {
      return api.Import.poolOCRResult();
    };

    const cb = payload => {
      dispatch({ type: actionTypes.OCR_POOLING_TICK, payload });
    };

    retryOperation(operation, cb, 20000, 100)
      .then(payload => {
        dispatch({ type: actionTypes.OCR_POOLING_FINISHED, payload });
      })
      .catch(error => {
        dispatch(snackbarActions.openSnackbar(JSON.stringify(error)));
        dispatch({ type: actionTypes.OCR_POOLING_ERROR, error });
      });
  },

  terminateRunningOCR: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.TERMINATE_RUNNING_OCR_STARTED });

    return api.Import.terminateRunningOCR().then(
      payload => dispatch({ type: actionTypes.TERMINATE_RUNNING_OCR_SUCCESS, payload }),
      ({ error }) => {
        dispatch({ type: actionTypes.TERMINATE_RUNNING_OCR_ERROR, error });
        dispatch(snackbarActions.openSnackbar(error));
      },
    );
  },

  changeSorting: sorting => dispatch => dispatch({ type: actionTypes.CHANGE_SORTING, sorting }),

  setRowPerPage: value => dispatch => dispatch({ type: actionTypes.SET_ROWS_PER_PAGE, value }),

  changePage: pageIndex => dispatch => dispatch({ type: actionTypes.CHANGE_PAGE, pageIndex }),
};
