import keyMirror from 'key-mirror';

import snackbarActions from './snackbar';

import { FINANCES_TABS_KEYS, AGENT_COMMISSION_REASON_KEYS } from '../../constants';

export const actionTypes = keyMirror({
  FINANCES_SET_EXPANDED_IDS: null,
  FINANCES_SET_EDITED_ROW: null,
  FINANCES_CHANGE_DRAFTS: null,
  FINANCES_CHANGE_DRAFTS_ERRORS: null,
  FINANCES_CHANGE_TAB: null,
  FINANCES_CHANGE_PAGINATION: null,
  FINANCES_CHANGE_FILTER: null,
  FINANCES_CHANGE_SORTING: null,
  FINANCES_UPDATE_ROW: null,
  FINANCES_SELECT_ROW: null,

  // GET, UPDATE ORDERS
  GET_ORDERS_ASYNC_START: null,
  GET_ORDERS_ASYNC_SUCCESS: null,
  GET_ORDERS_ASYNC_ERROR: null,
  UPDATE_ORDER_ASYNC_START: null,
  UPDATE_ORDER_ASYNC_SUCCESS: null,
  UPDATE_ORDER_ASYNC_ERROR: null,

  // GET, UPDATE COMMISSIONS
  GET_COMMISSIONS_ASYNC_START: null,
  GET_COMMISSIONS_ASYNC_SUCCESS: null,
  GET_COMMISSIONS_ASYNC_ERROR: null,
  UPDATE_AGENT_COMMISSION_ASYNC_START: null,
  UPDATE_AGENT_COMMISSION_ASYNC_SUCCESS: null,
  UPDATE_AGENT_COMMISSION_ASYNC_ERROR: null,

  // GET_RECEIPTS
  GET_RECEIPTS_ASYNC_START: null,
  GET_RECEIPTS_ASYNC_SUCCESS: null,
  GET_RECEIPTS_ASYNC_ERROR: null,

  // ORDER PAYMENT CRUD
  CREATE_ORDER_PAYMENT_START: null,
  CREATE_ORDER_PAYMENT_SUCCESS: null,
  CREATE_ORDER_PAYMENT_ERROR: null,
  UPDATE_ORDER_PAYMENT_START: null,
  UPDATE_ORDER_PAYMENT_SUCCESS: null,
  UPDATE_ORDER_PAYMENT_ERROR: null,
  DELETE_ORDER_PAYMENT_START: null,
  DELETE_ORDER_PAYMENT_SUCCESS: null,
  DELETE_ORDER_PAYMENT_ERROR: null,

  // ORDER DELIVERY CRUD
  CREATE_ORDER_DELIVERY_START: null,
  CREATE_ORDER_DELIVERY_SUCCESS: null,
  CREATE_ORDER_DELIVERY_ERROR: null,
  UPDATE_ORDER_DELIVERY_START: null,
  UPDATE_ORDER_DELIVERY_SUCCESS: null,
  UPDATE_ORDER_DELIVERY_ERROR: null,
  DELETE_ORDER_DELIVERY_START: null,
  DELETE_ORDER_DELIVERY_SUCCESS: null,
  DELETE_ORDER_DELIVERY_ERROR: null,

  // ORDER PRODUCT CRUD
  CREATE_ORDER_PRODUCT_START: null,
  CREATE_ORDER_PRODUCT_SUCCESS: null,
  CREATE_ORDER_PRODUCT_ERROR: null,
  UPDATE_ORDER_PRODUCT_START: null,
  UPDATE_ORDER_PRODUCT_SUCCESS: null,
  UPDATE_ORDER_PRODUCT_ERROR: null,
  DELETE_ORDER_PRODUCT_START: null,
  DELETE_ORDER_PRODUCT_SUCCESS: null,
  DELETE_ORDER_PRODUCT_ERROR: null,

  // RECEIPT CRUD
  CREATE_RECEIPT_ASYNC_START: null,
  CREATE_RECEIPT_ASYNC_SUCCESS: null,
  CREATE_RECEIPT_ASYNC_ERROR: null,
  UPDATE_RECEIPT_ASYNC_START: null,
  UPDATE_RECEIPT_ASYNC_SUCCESS: null,
  UPDATE_RECEIPT_ASYNC_ERROR: null,
  DELETE_RECEIPT_ASYNC_START: null,
  DELETE_RECEIPT_ASYNC_SUCCESS: null,
  DELETE_RECEIPT_ASYNC_ERROR: null,
  REBUILD_RECEIPT_ASYNC_START: null,
  REBUILD_RECEIPT_ASYNC_SUCCESS: null,
  REBUILD_RECEIPT_ASYNC_ERROR: null,

  // STATISTICS
  GET_FINANCE_STATISTICS_ASYNC_START: null,
  GET_FINANCE_STATISTICS_ASYNC_SUCCESS: null,
  GET_FINANCE_STATISTICS_ASYNC_ERROR: null,
});

export default {
  getStatistics: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_FINANCE_STATISTICS_ASYNC_START });

    const { finances } = getState();
    const { filters } = finances[FINANCES_TABS_KEYS.STATISTICS];

    return api.Statistic.getFinanceStatistics(filters).then(
      payload => dispatch({ type: actionTypes.GET_FINANCE_STATISTICS_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.get_statistics_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.GET_FINANCE_STATISTICS_ASYNC_ERROR, error });
      },
    );
  },

  getOrders: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_ORDERS_ASYNC_START });

    const { finances } = getState();
    const { filters, limit, sorting, offset } = finances[FINANCES_TABS_KEYS.ORDERS];

    const params = {
      ...filters,
      ...(sorting && { orderBy: sorting.field, orderMethod: sorting.sort }),
      limit,
      offset,
    };

    return api.Finances.getOrders(params).then(
      payload => dispatch({ type: actionTypes.GET_ORDERS_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.get_orders_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.GET_ORDERS_ASYNC_ERROR, error });
      },
    );
  },

  getReceipts: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_RECEIPTS_ASYNC_START });

    const { finances } = getState();
    const { filters, limit, sorting, offset } = finances[FINANCES_TABS_KEYS.RECEIPTS];

    const params = {
      ...filters,
      ...(sorting && { orderBy: sorting.field, orderMethod: sorting.sort }),
      limit,
      offset,
    };

    return api.Finances.getReceipts(params).then(
      payload => dispatch({ type: actionTypes.GET_RECEIPTS_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.get_receipts_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.GET_RECEIPTS_ASYNC_ERROR, error });
      },
    );
  },

  getCommissions: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_COMMISSIONS_ASYNC_START });

    const { finances } = getState();
    const { activeTab } = finances;
    const { filters, limit, sorting, offset } = finances[activeTab];

    // auto filter based on current Tab
    const reasons =
      activeTab === FINANCES_TABS_KEYS.COMMISSIONS
        ? [AGENT_COMMISSION_REASON_KEYS.SALE]
        : FINANCES_TABS_KEYS.DOWNLINES
        ? [
            AGENT_COMMISSION_REASON_KEYS.L1,
            AGENT_COMMISSION_REASON_KEYS.L2,
            AGENT_COMMISSION_REASON_KEYS.L3,
            AGENT_COMMISSION_REASON_KEYS.CA,
          ]
        : null;

    const params = {
      ...filters,
      ...(sorting && { orderBy: sorting.field, orderMethod: sorting.sort }),
      ...(reasons && { reasons }),
      activeTab,
      limit,
      offset,
    };

    return api.Finances.getAgentCommissions(params).then(
      payload => dispatch({ type: actionTypes.GET_COMMISSIONS_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.get_commissions_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.GET_COMMISSIONS_ASYNC_ERROR, error });
      },
    );
  },

  updateOrder: (order_id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_ORDER_ASYNC_START });

    return api.Finances.updateOrder(order_id, data).then(
      payload => {
        dispatch(snackbarActions.openSnackbar('cmp.snackbar.order_updated_success', 'success'));
        return dispatch({ type: actionTypes.UPDATE_ORDER_ASYNC_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_updated_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.UPDATE_ORDER_ASYNC_ERROR, error });
      },
    );
  },

  updateAgentCommission: (agent_commission_id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_AGENT_COMMISSION_ASYNC_START });

    return api.Finances.updateAgentCommission(agent_commission_id, data).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.agent_commission_updated_success', 'success'),
        );
        return dispatch({ type: actionTypes.UPDATE_AGENT_COMMISSION_ASYNC_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.agent_commission_updated_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.UPDATE_AGENT_COMMISSION_ASYNC_ERROR, error });
      },
    );
  },

  createOrderPayment: (order_id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.CREATE_ORDER_PAYMENT_START });

    return api.Finances.createOrderPayment(order_id, data).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_payment_created_success', 'success'),
        );
        return dispatch({ type: actionTypes.CREATE_ORDER_PAYMENT_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_payment_created_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.CREATE_ORDER_PAYMENT_ERROR, error });
      },
    );
  },

  updateOrderPayment: (order_id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_ORDER_PAYMENT_START });

    return api.Finances.updateOrderPayment(order_id, data).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_payment_updated_success', 'success'),
        );
        return dispatch({ type: actionTypes.UPDATE_ORDER_PAYMENT_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_payment_updated_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.UPDATE_ORDER_PAYMENT_ERROR, error });
      },
    );
  },

  deleteOrderPayment: id => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.DELETE_ORDER_PAYMENT_START });

    return api.Finances.deleteOrderPayment(id).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_payment_deleted_success', 'success'),
        );
        return dispatch({ type: actionTypes.DELETE_ORDER_PAYMENT_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_payment_deleted_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.DELETE_ORDER_PAYMENT_ERROR, error });
      },
    );
  },

  createOrderDelivery: (order_id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.CREATE_ORDER_DELIVERY_START });

    return api.Finances.createOrderDelivery(order_id, data).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_delivery_created_success', 'success'),
        );
        return dispatch({ type: actionTypes.CREATE_ORDER_DELIVERY_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_delivery_created_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.CREATE_ORDER_DELIVERY_ERROR, error });
      },
    );
  },

  updateOrderDelivery: (order_id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_ORDER_DELIVERY_START });

    return api.Finances.updateOrderDelivery(order_id, data).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_delivery_updated_success', 'success'),
        );
        return dispatch({ type: actionTypes.UPDATE_ORDER_DELIVERY_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_delivery_updated_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.UPDATE_ORDER_DELIVERY_ERROR, error });
      },
    );
  },

  deleteOrderDelivery: id => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.DELETE_ORDER_DELIVERY_START });

    return api.Finances.deleteOrderDelivery(id).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_delivery_deleted_success', 'success'),
        );
        return dispatch({ type: actionTypes.DELETE_ORDER_DELIVERY_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_delivery_deleted_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.DELETE_ORDER_DELIVERY_ERROR, error });
      },
    );
  },

  createOrderProduct: (order_id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.CREATE_ORDER_PRODUCT_START });

    return api.Finances.createOrderProduct(order_id, data).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_product_created_success', 'success'),
        );
        return dispatch({ type: actionTypes.CREATE_ORDER_PRODUCT_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_product_created_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.CREATE_ORDER_PRODUCT_ERROR, error });
      },
    );
  },

  updateOrderProduct: (order_id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_ORDER_PRODUCT_START });

    return api.Finances.updateOrderProduct(order_id, data).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_product_updated_success', 'success'),
        );
        return dispatch({ type: actionTypes.UPDATE_ORDER_PRODUCT_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_product_updated_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.UPDATE_ORDER_PRODUCT_ERROR, error });
      },
    );
  },

  deleteOrderProduct: id => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.DELETE_ORDER_PRODUCT_START });

    return api.Finances.deleteOrderProduct(id).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_product_deleted_success', 'success'),
        );
        return dispatch({ type: actionTypes.DELETE_ORDER_PRODUCT_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.order_product_deleted_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.DELETE_ORDER_PRODUCT_ERROR, error });
      },
    );
  },

  createReceipt: (agent_id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.CREATE_RECEIPT_ASYNC_START });

    return api.Finances.createReceipt(agent_id, data).then(
      payload => {
        dispatch(snackbarActions.openSnackbar('cmp.snackbar.receipt_created_success', 'success'));
        return dispatch({ type: actionTypes.CREATE_RECEIPT_ASYNC_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.receipt_created_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.CREATE_RECEIPT_ASYNC_ERROR, error });
      },
    );
  },

  updateReceipt: (receipt_id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_RECEIPT_ASYNC_START });

    return api.Finances.updateReceipt(receipt_id, data).then(
      payload => {
        dispatch(snackbarActions.openSnackbar('cmp.snackbar.receipt_updated_success', 'success'));
        return dispatch({ type: actionTypes.UPDATE_RECEIPT_ASYNC_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.receipt_updated_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.UPDATE_RECEIPT_ASYNC_ERROR, error });
      },
    );
  },

  deleteReceipt: receipt_id => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.DELETE_RECEIPT_ASYNC_START });

    return api.Finances.deleteReceipt(receipt_id).then(
      payload => {
        dispatch(snackbarActions.openSnackbar('cmp.snackbar.receipt_deleted_success', 'success'));
        return dispatch({ type: actionTypes.DELETE_RECEIPT_ASYNC_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.receipt_deleted_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.DELETE_RECEIPT_ASYNC_ERROR, error });
      },
    );
  },

  rebuildReceipt: receipt_id => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.REBUILD_RECEIPT_ASYNC_START });

    return api.Finances.rebuildReceipt(receipt_id).then(
      payload => {
        dispatch(snackbarActions.openSnackbar('cmp.snackbar.receipt_rebuild_success', 'success'));
        return dispatch({ type: actionTypes.REBUILD_RECEIPT_ASYNC_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.receipt_rebuild_error', 'error', {
            error: JSON.stringify(error?.message || error),
          }),
        );
        return dispatch({ type: actionTypes.REBUILD_RECEIPT_ASYNC_ERROR, error });
      },
    );
  },

  updateRow: (order_id, data, path) => dispatch =>
    dispatch({ type: actionTypes.FINANCES_UPDATE_ROW, order_id, data, path }),

  changeSorting: sorting => dispatch =>
    dispatch({ type: actionTypes.FINANCES_CHANGE_SORTING, sorting }),

  changeFilter: filter => dispatch =>
    dispatch({ type: actionTypes.FINANCES_CHANGE_FILTER, filter }),

  changePagination: payload => dispatch =>
    dispatch({ type: actionTypes.FINANCES_CHANGE_PAGINATION, payload }),

  changeTab: tab => dispatch => dispatch({ type: actionTypes.FINANCES_CHANGE_TAB, tab }),

  setExpandedRows: expandedIds => dispatch =>
    dispatch({ type: actionTypes.FINANCES_SET_EXPANDED_IDS, expandedIds }),

  setEditedRow: id => dispatch => dispatch({ type: actionTypes.FINANCES_SET_EDITED_ROW, id }),

  changeDrafts: data => dispatch => dispatch({ type: actionTypes.FINANCES_CHANGE_DRAFTS, data }),

  changeDraftsErrors: errors => dispatch =>
    dispatch({ type: actionTypes.FINANCES_CHANGE_DRAFTS_ERRORS, errors }),

  setSelectedRows: payload => dispatch =>
    dispatch({ type: actionTypes.FINANCES_SELECT_ROW, payload }),
};
