import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import combinedReducers from './reducers';
import api from '../api';

// Be sure to ONLY add this middleware in development!
// const middleware =
//   process.env.NODE_ENV !== 'production'
//     ? [require('redux-immutable-state-invariant').default(), thunkMiddleware.withExtraArgument(api)]
//     : [thunkMiddleware.withExtraArgument(api)];
const middleware = [thunkMiddleware.withExtraArgument(api)];

export default function initializeStore(initState = {}) {
  return createStore(
    combinedReducers,
    initState,
    composeWithDevTools(applyMiddleware(...middleware)),
  );
}
