import { actionTypes } from '../actions/academy';

const initialState = {
  category: null,
  activeItemId: null,
  activeContentType: null,
  selectedAudios: [],
  search: '',
  contentData: {
    isLoading: false,
    error: null,
    data: {
      slides: [],
      videos: [],
      audios: [],
      docs: [],
    },
    filteredData: null,
  },
};

const academyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVE_ITEM:
      return {
        ...state,
        activeItemId: action.payload.id,
        activeContentType: action.payload.contentType,
      };
    case actionTypes.SET_CONTENT_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case actionTypes.SET_SELECTED_AUDIOS:
      return {
        ...state,
        selectedAudios: [...state.selectedAudios, action.payload],
      };
    case actionTypes.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case actionTypes.SET_SEARCH_ACTION:
      let filteredData = {};
      const { search } = state;
      const { data } = state.contentData;
      if (search) {
        Object.entries(data).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            let newData = [];
            value.forEach(item => {
              let itemData = item?.data;
              itemData.forEach(item => {
                if (item.name.toUpperCase().includes(search.toUpperCase())) {
                  newData.push(item);
                }
              });
            });
            filteredData[key] = [{ header: '', data: newData }];
          }
        });
      } else {
        filteredData = null;
      }
      return {
        ...state,
        contentData: { ...state.contentData, filteredData },
      };
    case actionTypes.SUBPAGE_CLOSE:
      return {
        ...state,
        category: null,
        search: '',
        contentData: { ...state.contentData, filteredData: null },
      };
    case actionTypes.GET_CONTENT_DATA_ASYNC_START:
      return {
        ...state,
        contentData: { ...state.contentData, error: null, isLoading: true },
      };
    case actionTypes.GET_CONTENT_DATA_ASYNC_SUCCESS:
      return {
        ...state,
        contentData: { ...state.contentData, error: null, isLoading: false },
      };
    case actionTypes.GET_CONTENT_DATA_ASYNC_ERROR:
      return {
        ...state,
        contentData: { ...state.contentData, error: action.payload },
      };

    case actionTypes.GET_SLIDES_ASYNC_SUCCESS:
      return {
        ...state,
        contentData: {
          ...state.contentData,
          data: { ...state.contentData.data, slides: action.payload },
        },
      };

    case actionTypes.GET_VIDEOS_ASYNC_SUCCESS:
      return {
        ...state,
        contentData: {
          ...state.contentData,
          data: { ...state.contentData.data, videos: action.payload },
        },
      };

    case actionTypes.GET_AUDIOS_ASYNC_SUCCESS:
      return {
        ...state,
        contentData: {
          ...state.contentData,
          data: { ...state.contentData.data, audios: action.payload },
        },
      };

    case actionTypes.GET_DOCS_ASYNC_SUCCESS:
      return {
        ...state,
        contentData: {
          ...state.contentData,
          data: { ...state.contentData.data, docs: action.payload },
        },
      };

    default:
      return state;
  }
};

export default academyReducer;
