import keyMirror from 'key-mirror';

export const actionTypes = keyMirror({
  OPEN_SNACKBAR: null,
  CLOSE_SNACKBAR: null,
});

export default {
  openSnackbar:
    (message, type = 'error', iData = null) =>
    dispatch => {
      if (!message) console.error('Provide message in order to open snackbar');
      dispatch({ type: actionTypes.OPEN_SNACKBAR, data: { message, type, iData } });
    },

  closeSnackbar: () => dispatch => dispatch({ type: actionTypes.CLOSE_SNACKBAR }),

  /**
   * Generic function to handle:
   * 1. "Unknown" server errors (status 500 or errors with string type)
   * 2. Errors that specifically need to be shown in snackbar - the convention is that error obj has to have 'general' key
   */
  processError: error => dispatch => {
    if (error?.status === 500 || typeof error?.message === 'string' || error?.general) {
      dispatch({
        type: actionTypes.OPEN_SNACKBAR,
        data: { message: error?.general || error?.message, iData: null },
      });
    }
  },
};
