const fetch = require('isomorphic-unfetch');

const buildURL = require('./buildURL');

const sendRequest = (method, url, opts = {}) => {
  const headers = Object.assign({}, opts.headers || {}, {
    'Content-type': 'application/json; charset=UTF-8',
  });

  return fetch(url, Object.assign({ method, credentials: 'same-origin' }, opts, { headers }));
};

const getErrorObject = (response, parsedResponse) => {
  const { status } = response;

  switch (typeof parsedResponse) {
    case 'string':
      return {
        message: parsedResponse,
        status,
      };
    case 'object':
      return {
        ...parsedResponse,
        status,
      };
    default:
      return {
        message: JSON.stringify(parsedResponse),
        status,
      };
  }
};

const parseResponse = async response => {
  const parsedResponse = await response.json();
  if (response.ok) {
    return parsedResponse;
  }

  // get parsed body with status code
  const error = getErrorObject(response, parsedResponse);

  return Promise.reject(error);
};

module.exports = {
  get: (url, params, options) =>
    sendRequest('GET', buildURL(url, params), options).then(parseResponse),
  del: (url, data, params) =>
    sendRequest('DELETE', buildURL(url, params), { body: JSON.stringify(data) }).then(
      parseResponse,
    ),
  put: (url, data, params) =>
    sendRequest('PUT', buildURL(url, params), { body: JSON.stringify(data) }).then(parseResponse),
  post: (url, data, params, headers) =>
    sendRequest('POST', buildURL(url, params), { body: JSON.stringify(data), headers }).then(
      parseResponse,
    ),
};
