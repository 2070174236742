import keyMirror from 'key-mirror';

import snackbarActions from './snackbar';

// export goes only to the reducer
export const actionTypes = keyMirror({
  GET_USER_PRODUCTS_ASYNC_SUCCESS: null,
  GET_USER_PRODUCTS_ASYNC_ERROR: null,
  GET_USER_REC_COUNT_ASYNC_SUCCESS: null,
  GET_USER_REC_COUNT_ASYNC_ERROR: null,
  GET_USER_ASYNC_START: null,
  GET_USER_ASYNC_SUCCESS: null,
  GET_USER_ASYNC_ERROR: null,
  EDIT_USER_ASYNC_START: null,
  EDIT_USER_ASYNC_SUCCESS: null,
  EDIT_USER_ASYNC_ERROR: null,
  CREATE_USER_ASYNC_START: null,
  CREATE_USER_ASYNC_SUCCESS: null,
  CREATE_USER_ASYNC_ERROR: null,
  DELETE_USER_ASYNC_START: null,
  DELETE_USER_ASYNC_SUCCESS: null,
  DELETE_USER_ASYNC_ERROR: null,
  DELETE_USER_INFO_SUCCESS: null,
  DELETE_USER_INFO_ERROR: null,
  ADD_USER_INFO_SUCCESS: null,
  ADD_USER_INFO_ERROR: null,
  ADD_SUB_USER: null,
  USER_DRAFT_CHANGE: null,
  USER_DRAFT_RESET: null,
  DISCARD_STATE: null,
  CHANGE_CURRENT_TAB: null,
  CHANGE_MODE: null,
});

// it is conveniently that actions encapsulates the api object, actionTypes and dispatch method
export default {
  addSubUser: () => dispatch => {
    dispatch({ type: actionTypes.ADD_SUB_USER });
  },

  handleChangeMode: mode => dispatch => {
    dispatch({ type: actionTypes.CHANGE_MODE, mode });
  },

  getUser: id => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_USER_ASYNC_START });
    return api.User.getUser(id).then(
      payload => dispatch({ type: actionTypes.GET_USER_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_USER_ASYNC_ERROR, error }),
    );
  },

  /**
   * @param {Object} changes
   * @param {Object} changes.data
   * @param {Object} changes.errors
   * @returns {?Promise}
   */
  handleDraftChange: changes => dispatch => {
    dispatch({ type: actionTypes.USER_DRAFT_CHANGE, changes });
  },

  handleResetDraft: () => dispatch => {
    dispatch({ type: actionTypes.USER_DRAFT_RESET });
  },

  discardState: () => dispatch => {
    dispatch({ type: actionTypes.DISCARD_STATE });
  },

  editUser: (id, data) => (dispatch, getState, api) =>
    api.User.editUser(id, data).then(
      payload => {
        dispatch(snackbarActions.openSnackbar('cmp.snackbar.user_updated_success', 'success'));
        return dispatch({ type: actionTypes.EDIT_USER_ASYNC_SUCCESS, payload });
      },
      err => {
        const error = err?.error || err?.message || err;
        const message = typeof error === 'string' ? error : JSON.stringify(error);
        dispatch(snackbarActions.openSnackbar(message));
        return dispatch({ type: actionTypes.EDIT_USER_ASYNC_ERROR, error });
      },
    ),

  createUser: data => (dispatch, getState, api) =>
    api.User.createUser(data).then(
      payload => {
        dispatch(snackbarActions.openSnackbar('cmp.snackbar.user_created_success', 'success'));
        return dispatch({ type: actionTypes.CREATE_USER_ASYNC_SUCCESS, payload });
      },
      error => {
        dispatch(snackbarActions.processError(error));
        return dispatch({ type: actionTypes.CREATE_USER_ASYNC_ERROR, error });
      },
    ),

  deleteUser: id => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.DELETE_USER_ASYNC_START });
    return api.User.deleteUser(id).then(
      payload => dispatch({ type: actionTypes.DELETE_USER_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.DELETE_USER_ASYNC_ERROR, error }),
    );
  },

  deleteUserInfo: (userId, data) => (dispatch, getState, api) =>
    api.User.deleteUserInfo(userId, data).then(
      payload => dispatch({ type: actionTypes.DELETE_USER_INFO_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.DELETE_USER_INFO_ERROR, error }),
    ),

  addUserInfo: (userId, data) => (dispatch, getState, api) =>
    api.User.createUserInfo(userId, data).then(
      payload => dispatch({ type: actionTypes.ADD_USER_INFO_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.ADD_USER_INFO_ERROR, error }),
    ),

  getUserRecommendationsCount: id => (dispatch, getState, api) =>
    api.User.getUserRecommendationsCount(id).then(
      payload => dispatch({ type: actionTypes.GET_USER_REC_COUNT_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_USER_REC_COUNT_ASYNC_ERROR, error }),
    ),

  getUserProducts: id => (dispatch, getState, api) =>
    api.User.getUserProducts(id).then(
      payload => dispatch({ type: actionTypes.GET_USER_PRODUCTS_ASYNC_SUCCESS, payload }),
      payload => dispatch({ type: actionTypes.GET_USER_PRODUCTS_ASYNC_ERROR, payload }),
    ),

  changeCurrentTab: currentTab => dispatch =>
    dispatch({ type: actionTypes.CHANGE_CURRENT_TAB, currentTab }),
};
