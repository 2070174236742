import keyMirror from 'key-mirror';

import { PRESENTATION_LIST_PAGES } from '../../../containers/PresentationsList/constants';
import { PRESENTATION_STATUS, PRESENTATION_PERIOD } from '../../constants';

export const actionTypes = keyMirror({
  GET_PRESENTATIONS_ASYNC_START: null,
  GET_PRESENTATIONS_ASYNC_SUCCESS: null,
  GET_PRESENTATIONS_ASYNC_ERROR: null,
  GET_PRESENTATIONS_COUNTERS_ASYNC_SUCCESS: null,
  GET_PRESENTATIONS_COUNTERS_ASYNC_ERROR: null,
  RESET_PRESENTATIONS: null,
  CHANGE_FILTER_VALUE: null,
  CHANGE_FILTER_VISIBILITY: null,
  SET_PAGE: null,
});

const getPageDefaults = page => {
  switch (page) {
    case PRESENTATION_LIST_PAGES.DELIVERY:
      return {
        statuses: [PRESENTATION_STATUS.SUCCESS],
        needDelivery: true,
      };
    case PRESENTATION_LIST_PAGES.EXPIRED:
      return {
        period: PRESENTATION_PERIOD.EXPIRED,
        statuses: [PRESENTATION_STATUS.PLANNED],
      };
    case PRESENTATION_LIST_PAGES.FUTURE:
      return {
        period: PRESENTATION_PERIOD.FUTURE,
        statuses: [PRESENTATION_STATUS.PLANNED],
      };
    case PRESENTATION_LIST_PAGES.SUCCESSFUL:
      return {
        boughtMainProduct: true,
        statuses: [PRESENTATION_STATUS.SUCCESS],
      };
    case PRESENTATION_LIST_PAGES.HISTORY:
      return {};
    default:
      return {};
  }
};

const getFiltersFromStore = getState => {
  const {
    presentations: {
      filters: { search, statuses },
      page,
    },
  } = getState();

  // page-specific filters have the highest priority
  const pageDefaults = getPageDefaults(page);
  return { statuses, search, ...pageDefaults };
};

export default {
  getPresentations: params => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_PRESENTATIONS_ASYNC_START });
    const filtersFromStore = getFiltersFromStore(getState);
    // statuses from params overrides statuses from store! That`s because statuses from store are used only for
    // 'history' list, for other lists (successful, expired, etc.) statuses are hardcoded, and cannot be set differently
    const actualParams = {
      ...filtersFromStore,
      ...params,
    };
    return api.Presentations.getPresentations(actualParams).then(
      payload => dispatch({ type: actionTypes.GET_PRESENTATIONS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_PRESENTATIONS_ASYNC_ERROR, error }),
    );
  },

  changeFilterValue: (name, value) => dispatch =>
    dispatch({ type: actionTypes.CHANGE_FILTER_VALUE, name, value }),

  changeFiltersVisibility: () => dispatch =>
    dispatch({ type: actionTypes.CHANGE_FILTER_VISIBILITY }),

  getPresentationsCounters: params => (dispatch, getState, api) =>
    api.Presentations.getPresentationsCounters(params).then(
      payload => dispatch({ type: actionTypes.GET_PRESENTATIONS_COUNTERS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_PRESENTATIONS_COUNTERS_ASYNC_ERROR, error }),
    ),

  resetPresentations: () => dispatch => dispatch({ type: actionTypes.RESET_PRESENTATIONS }),

  setPage: page => dispatch => dispatch({ type: actionTypes.SET_PAGE, page }),
};
