import { actionTypes } from '../actions/general';
import { LOCATION_TYPES } from '../../constants';

const initialState = {
  statuses: {
    list: [],
    map: {},
    isLoading: false,
  },
  locationsTable: {
    rowModesModel: {},
  },
  dataGridSnapshots: {},
  locations: {
    list: [],
    total: 0,
    isLoading: false,
    isVisibleAddNew: false,
    isVisibleDeleteDialog: false,
  },
  locationsQueryParams: {
    type: LOCATION_TYPES.MALL,
    orderBy: 'code',
    orderMethod: 'desc',
    search: '',
    limit: 50,
    offset: 0,
  },
  location: {
    deleted_id: '',
    edited_id: '',
    error: null,
    message: null,
    message_type: null,
    code: {},
    city: {},
    display_name: {},
    index: {},
  },
  editedLocations: {},
  salesAgents: [],
  callAgents: [],
  activeCallAgents: [],
  activeSalesAgents: [],
  appSettings: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TABLE_SNAPSHOTS_ASYNC_SUCCESS:
    case actionTypes.UPDATE_TABLE_SNAPSHOT_ASYNC_SUCCESS: {
      return {
        ...state,
        dataGridSnapshots: action.payload,
      };
    }
    case actionTypes.SET_MOBILE_INPUT:
      return {
        ...state,
        locationsTable: {
          rowModesModel: { ...action.payload },
        },
      };
    case actionTypes.SET_ROW_MODES_MODEL:
      return {
        ...state,
        locationsTable: {
          rowModesModel: { ...action.payload },
        },
      };
    case actionTypes.UPDATE_EDITED_LOCATION:
      return {
        ...state,
        location: {
          ...action.payload,
        },
      };
    case actionTypes.SET_EDITED_LOCATION:
      return {
        ...state,
        location: {
          ...state.location,
          error: null,
          ...action.payload,
        },
      };
    case actionTypes.SET_LOCATION_ERROR:
      return {
        ...state,
        location: {
          ...state.location,
          error: action.payload,
        },
      };
    case actionTypes.CLEAR_LOCATION_FIELDS:
      return {
        ...state,
        location: {
          edited_id: '',
          error: null,
          code: {},
          city: {},
          display_name: {},
          index: {},
        },
      };
    case actionTypes.DELETE_LOCATION_ERROR:
      return {
        ...state,
        location: {
          ...state.location,
          deleted_id: '',
        },
      };
    case actionTypes.NEW_LOCATION_FIELD_CHANGE:
      return {
        ...state,
        location: action.payload,
      };
    case actionTypes.IS_VISIBLE_ADD_NEW:
      return {
        ...state,
        locations: {
          ...state.locations,
          isVisibleAddNew: action.payload,
        },
        location: {
          deleted_id: '',
          edited_id: '',
          error: null,
          code: {},
          city: {},
          display_name: {},
          index: {},
        },
      };
    case actionTypes.IS_VISIBLE_DELETE:
      return {
        ...state,
        location: {
          ...state.location,
          deleted_id: action.payload,
        },
      };
    case actionTypes.SET_SEARCH_VALUE:
      return {
        ...state,
        locationsQueryParams: {
          ...state.locationsQueryParams,
          search: action.payload,
        },
      };
    case actionTypes.GET_STATUSES_ASYNC_START:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          isLoading: true,
        },
      };
    case actionTypes.GET_STATUSES_ASYNC_SUCCESS:
      return {
        ...state,
        statuses: {
          ...state.statuses,
          list: action.payload,
          map: action.payload.reduce((acc, { code, ...rest }) => {
            acc[code] = { ...rest };
            return acc;
          }, {}),
          isLoading: false,
        },
      };
    case actionTypes.SET_ACTIVE_LOCATION_TYPE:
      return {
        ...state,
        locationsQueryParams: {
          ...state.locationsQueryParams,
          type: action.payload,
          search: '',
        },
      };
    case actionTypes.GET_LOCATIONS_ASYNC_START:
    case actionTypes.GET_LOCATIONS_W_USERS_ASYNC_START:
      return {
        ...state,
        locations: {
          ...state.locations,
          list: [],
          isLoading: true,
        },
      };
    case actionTypes.GET_LOCATIONS_ASYNC_SUCCESS:
    case actionTypes.GET_LOCATIONS_W_USERS_ASYNC_SUCCESS:
      return {
        ...state,
        locations: {
          ...state.locations,
          list: action.payload.data,
          total: action.payload.total,
          isLoading: false,
        },
        locationsQueryParams: {
          ...state.locationsQueryParams,
          orderBy: action.payload.orderBy,
          orderMethod: action.payload.orderMethod,
          limit: action.payload.limit,
          offset: action.payload.offset,
        },
      };
    case actionTypes.GET_LOCATIONS_ASYNC_ERROR:
    case actionTypes.GET_LOCATIONS_W_USERS_ASYNC_ERROR:
      return {
        ...state,
        locations: {
          ...state.locations,
          list: [],
          isLoading: false,
        },
      };
    case actionTypes.ADD_LOCATION_ASYNC_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        locations: {
          ...state.locations,
          list: [{ ...data, users: 0 }, ...state.locations.list],
          isLoading: false,
        },
      };
    }
    case actionTypes.GET_SALES_AGENTS_ASYNC_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        salesAgents: payload.sort((a, b) => a.name.localeCompare(b.name)),
        activeSalesAgents: payload
          .filter(agent => !!agent?.is_active)
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    }
    case actionTypes.GET_CALL_AGENTS_ASYNC_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        callAgents: data.sort((a, b) => a.name.localeCompare(b.name)),
        activeCallAgents: data
          .filter(agent => !!agent?.is_active)
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    }
    case actionTypes.UPDATE_LOCATION_ASYNC_SUCCESS: {
      // we need to merge 'users' count from old row with updated one
      const { data: updated } = action.payload;
      const old = state.locations.list.find(({ id }) => id === updated.id);
      const merged = { ...old, ...updated };
      return {
        ...state,
        locations: {
          ...state.locations,
          list: [merged, ...state.locations.list.filter(({ id }) => id !== merged.id)],
          isLoading: false,
        },
        location: {
          edited_id: '',
          error: null,
          code: {},
          city: {},
          display_name: {},
          index: {},
        },
      };
    }
    case actionTypes.UPDATE_LOCATION_ASYNC_ERROR: {
      return {
        ...state,
        location: {
          ...state.location,
          error: action.payload,
        },
      };
    }
    case actionTypes.GET_APP_SETTINGS_ASYNC_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        appSettings: payload,
      };
    }
    case actionTypes.UPDATE_APP_SETTINGS_ASYNC_SUCCESS: {
      const {
        payload: { updatedSettings },
      } = action;
      return {
        ...state,
        appSettings: {
          ...state.appSettings,
          ...updatedSettings,
        },
      };
    }
    default:
      return state;
  }
};

export default authReducer;
