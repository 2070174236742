import moment from 'moment';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';

import { actionTypes } from '../actions/statistic';
import { STATISTIC_TAB_KEYS } from '../../../containers/Statistic/constants';

const initialState = {
  filters: {
    year: moment().get('Y').toString(),
  },
  currentTab: STATISTIC_TAB_KEYS.SALES_AGENT,
  dashboard: {
    data: null,
    isLoading: false,
    error: null,
  },
  salesAgentsData: [],
  callAgentsData: [],
  leadsData: [],
};

const statisticReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SALES_AGENT_DATA_ASYNC_SUCCESS:
      return {
        ...state,
        dashboard: {
          error: null,
          isLoading: false,
          data: { ...state.dashboard.data, ...action.payload.charts },
        },
        salesAgentsData: action.payload.table,
      };
    case actionTypes.GET_SALES_AGENT_DATA_ASYNC_START:
    case actionTypes.GET_CALL_AGENT_DATA_ASYNC_START:
      return {
        ...state,
        dashboard: {
          ...state.dashboard.data,
          error: null,
          isLoading: true,
        },
      };
    case actionTypes.GET_CALL_AGENT_DATA_ASYNC_SUCCESS:
      return {
        ...state,
        dashboard: {
          error: null,
          isLoading: false,
          data: { ...state.dashboard.data, ...action.payload.charts },
        },
        callAgentsData: action.payload.table,
      };
    case actionTypes.GET_LEADS_DATA_ASYNC_SUCCESS:
      return {
        ...state,
        leadsData: action.payload,
      };

    case actionTypes.CHANGE_STATISTIC_TAB:
      return {
        ...state,
        currentTab: action.tab,
        filters: {
          year: moment().get('Y').toString(),
        },
      };

    case actionTypes.GET_DASHBOARD_DATA_ASYNC_START:
      return {
        ...state,
        dashboard: { ...initialState.dashboard, isLoading: true },
      };

    case actionTypes.GET_DASHBOARD_DATA_ASYNC_SUCCESS:
      return {
        ...state,
        dashboard: {
          data: action.payload,
          error: null,
          isLoading: false,
        },
      };

    case actionTypes.ADD_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.data,
        },
      };

    case actionTypes.REMOVE_FILTER:
      return {
        ...state,
        filters: omit(state.filters, action.name),
      };

    default:
      return state;
  }
};

export default statisticReducer;
