import isEmpty from 'lodash/isEmpty';

import { actionTypes } from '../actions/users';
import { actionTypes as userActionTypes } from '../actions/user';

import { replaceAt } from '../../utils';

const initialState = {
  filters: {
    visible: true,
    preset: {
      statuses: [],
      presentationStatuses: [],
      search: '',
    },
    sorted: [],
    /**
     * {Object[]} custom - array which represent custom filters:
     * custom[].name - {string} - field name
     * custom[].type - {string} - one of: TEXT, ENUM, DATE, BOOL
     * custom[].value - {string|boolean|object*} - field value
     * *object in case of DATE type: { from: 'ISOdate', to: 'ISOdate' }
     */
    custom: [],
  },
  list: [],
  offset: 0,
  limit: 100,
  total: 0,
  isLoading: false,
  listError: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_ASYNC_START:
    case actionTypes.GET_SA_USERS_ASYNC_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_USERS_ASYNC_SUCCESS:
    case actionTypes.GET_USERS_WITH_ACCOUNTS_ASYNC_SUCCESS:
    case actionTypes.GET_SA_USERS_ASYNC_SUCCESS: {
      const {
        payload: { data, offset, total, limit },
      } = action;
      return {
        ...state,
        offset,
        limit,
        total,
        list: data,
        listError: null,
        isLoading: false,
      };
    }
    case actionTypes.GET_USERS_ASYNC_ERROR:
    case actionTypes.GET_USERS_WITH_ACCOUNTS_ASYNC_ERROR:
    case actionTypes.GET_SA_USERS_ASYNC_ERROR: {
      const { error } = action;
      return {
        ...state,
        listError: error,
        isLoading: false,
      };
    }
    case actionTypes.RESET_USERS:
      return initialState;
    case userActionTypes.DELETE_USER_INFO_SUCCESS: {
      const { list: oldList } = state;
      const { data } = action.payload;
      if (isEmpty(data)) return state;
      const index = oldList.findIndex(({ id }) => id === data.id);
      if (index === -1) {
        console.error('Unable to find row index');
        return state;
      }
      return {
        ...state,
        list: replaceAt(oldList, index, data),
      };
    }
    case actionTypes.BLOCK_ACCOUNT:
    case actionTypes.UNBLOCK_ACCOUNT: {
      const { list: oldList } = state;
      const {
        data: { updatedId, is_active },
      } = action.payload;
      const index = oldList.findIndex(({ id }) => id === updatedId);
      const user = oldList.find(({ id }) => id === updatedId);
      return {
        ...state,
        list: replaceAt(oldList, index, { ...user, is_active }),
      };
    }
    default:
      return state;
  }
};

export default userReducer;
