import keyMirror from 'key-mirror';

import snackbarActions from './snackbar';

export const actionTypes = keyMirror({
  GET_SALES_AGENT_DATA_ASYNC_START: null,
  GET_SALES_AGENT_DATA_ASYNC_ERROR: null,
  GET_SALES_AGENT_DATA_ASYNC_SUCCESS: null,
  GET_CALL_AGENT_DATA_ASYNC_START: null,
  GET_CALL_AGENT_DATA_ASYNC_ERROR: null,
  GET_CALL_AGENT_DATA_ASYNC_SUCCESS: null,
  GET_LEADS_DATA_ASYNC_START: null,
  GET_LEADS_DATA_ASYNC_ERROR: null,
  GET_LEADS_DATA_ASYNC_SUCCESS: null,
  GET_DASHBOARD_DATA_ASYNC_START: null,
  GET_DASHBOARD_DATA_ASYNC_ERROR: null,
  GET_DASHBOARD_DATA_ASYNC_SUCCESS: null,
  ADD_FILTER: null,
  REMOVE_FILTER: null,
  CHANGE_STATISTIC_TAB: null,
});

export default {
  getSalesAgentData: () => (dispatch, getState, api) => {
    const {
      statistic: { filters },
    } = getState();
    dispatch({ type: actionTypes.GET_SALES_AGENT_DATA_ASYNC_START });
    return api.Statistic.getSalesAgentData(filters).then(
      payload => dispatch({ type: actionTypes.GET_SALES_AGENT_DATA_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_SALES_AGENT_DATA_ASYNC_ERROR, error }),
    );
  },
  getCallAgentData: () => (dispatch, getState, api) => {
    const {
      statistic: { filters },
    } = getState();
    dispatch({ type: actionTypes.GET_CALL_AGENT_DATA_ASYNC_START });
    return api.Statistic.getCallAgentData(filters).then(
      payload => dispatch({ type: actionTypes.GET_CALL_AGENT_DATA_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_CALL_AGENT_DATA_ASYNC_ERROR, error }),
    );
  },
  getLeadsData: () => (dispatch, getState, api) => {
    const {
      statistic: { filters },
    } = getState();
    dispatch({ type: actionTypes.GET_LEADS_DATA_ASYNC_START });
    return api.Statistic.getLeadsData(filters).then(
      payload => dispatch({ type: actionTypes.GET_LEADS_DATA_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_LEADS_DATA_ASYNC_ERROR, error }),
    );
  },
  getDashboardData: params => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_DASHBOARD_DATA_ASYNC_START });
    return api.Statistic.getDashboardData(params).then(
      payload => dispatch({ type: actionTypes.GET_DASHBOARD_DATA_ASYNC_SUCCESS, payload }),
      error => {
        dispatch(snackbarActions.openSnackbar(error?.error || JSON.stringify(error)));
        dispatch({ type: actionTypes.GET_DASHBOARD_DATA_ASYNC_ERROR, error });
      },
    );
  },
  changeStatisticTab: tab => dispatch => dispatch({ type: actionTypes.CHANGE_STATISTIC_TAB, tab }),
  addFilter: data => dispatch => dispatch({ type: actionTypes.ADD_FILTER, data }),
  removeFilter: name => dispatch => dispatch({ type: actionTypes.REMOVE_FILTER, name }),
};
