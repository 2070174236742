import pick from 'lodash/pick';

import { actionTypes } from '../actions/presentation';

const USER_PAYLOAD_FIELDS = [
  'gender',
  'age',
  'name',
  'surname',
  'email',
  'zipcode',
  'address',
  'phone',
  'mobile_phone',
  'city',
];

const USER_PAYLOAD_ADDITIONAL_FIELDS = [
  'pet',
  'has_allergies',
  'working_status',
  'relations',
  'persons_at_home',
  'has_kids',
];

const initialState = {
  isLoading: false,
  stepHistory: ['00'],
  finalSteps: ['04', '18'],
  currentStep: '00',
  data: {
    date: null,
    // virtual fields, that are not persist in DB, and store temp information
    get_gift: null,
    saw_presentation: null,
    remeetDate: null,
    recallTime: null,
    buy_product: null,
    product_delivered: false,
    show_hula_products: null,
    show_other_products: null,
    change_profile_info: null,
    change_additional_info: null,
    serial_number: '',
    mainProduct: {
      price: 0,
      quantity: 1,
      model: null, // only EV for now
    },
    hulaProducts: [],
    otherProducts: [],
    user: {
      name: '',
      surname: '',
      address: '',
      gender: '',
    },
    sub_user: {
      gender: null,
      age: '',
      name: '',
      surname: '',
      email: '',
      zipcode: '',
      address: '',
      phone: '',
      mobile_phone: '',
      city: '',
    },
    // used to store user data that needed to be updated
    profileDrafts: null,
    // other products selects
    selectedCategory: '',
    selectedProduct: '',
    selectedStatus: null,
  },
};

const presentationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRESENTATION_ASYNC_START:
    case actionTypes.UPDATE_PRESENTATION_ASYNC_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PRESENTATION_ASYNC_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          ...data,
          profileDrafts: pick(data.user, [
            ...USER_PAYLOAD_FIELDS,
            ...USER_PAYLOAD_ADDITIONAL_FIELDS,
          ]),
        },
        isLoading: false,
      };
    }
    case actionTypes.UPDATE_PRESENTATION_ASYNC_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.CHANGE_DATA:
    case actionTypes.NEXT_STEP:
      // TODO -> rework from internal page state to store
      return state;
    case actionTypes.FINISH_PRESENTATION_ASYNC_SUCCESS: {
      const { data, error } = action.payload;
      const infoMsg = data ? 'Presentation flow saved successfully' : '';
      return {
        ...state,
        snackbar: {
          infoMsg,
          errorMsg: error || '',
          open: true,
        },
      };
    }
    case actionTypes.FINISH_PRESENTATION_ASYNC_ERROR: {
      const { error } = action.payload;
      return state;
    }
    default:
      return state;
  }
};

export default presentationReducer;
