import keyMirror from 'key-mirror';

export const actionTypes = keyMirror({
  CHANGE_FILTER: null,
  CHANGE_SORTED: null,
  CHANGE_PAGE: null,
  CHANGE_PAGINATION: null,
});

export default {
  changeFilter: filter => dispatch =>
    dispatch({ type: actionTypes.CHANGE_FILTER, payload: filter }),
};
