import keyMirror from 'key-mirror';
import snackbarActions from './snackbar';

export const actionTypes = keyMirror({
  SET_MOBILE_INPUT: null,
  SET_ROW_MODES_MODEL: null,
  UPDATE_EDITED_LOCATION: null,
  SET_EDITED_LOCATION: null,
  SET_LOCATION_ERROR: null,
  DELETE_LOCATION_ERROR: null,
  CLEAR_LOCATION_FIELDS: null,
  NEW_LOCATION_FIELD_CHANGE: null,
  IS_VISIBLE_ADD_NEW: null,
  IS_VISIBLE_DELETE: null,
  SET_SEARCH_VALUE: null,
  SET_ACTIVE_LOCATION_TYPE: null,
  GET_LOCATIONS_ASYNC_START: null,
  GET_LOCATIONS_ASYNC_SUCCESS: null,
  GET_LOCATIONS_ASYNC_ERROR: null,
  GET_SALES_AGENTS_ASYNC_SUCCESS: null,
  GET_SALES_AGENTS_ASYNC_ERROR: null,
  GET_CALL_AGENTS_ASYNC_SUCCESS: null,
  GET_CALL_AGENTS_ASYNC_ERROR: null,
  GET_LOCATIONS_W_USERS_ASYNC_START: null,
  GET_LOCATIONS_W_USERS_ASYNC_SUCCESS: null,
  GET_LOCATIONS_W_USERS_ASYNC_ERROR: null,
  ADD_LOCATION_ASYNC_START: null,
  ADD_LOCATION_ASYNC_SUCCESS: null,
  ADD_LOCATION_ASYNC_ERROR: null,
  UPDATE_LOCATION_ASYNC_START: null,
  UPDATE_LOCATION_ASYNC_SUCCESS: null,
  UPDATE_LOCATION_ASYNC_ERROR: null,
  GET_STATUSES_ASYNC_START: null,
  GET_STATUSES_ASYNC_SUCCESS: null,
  GET_STATUSES_ASYNC_ERROR: null,
  GET_APP_SETTINGS_ASYNC_SUCCESS: null,
  GET_APP_SETTINGS_ASYNC_ERROR: null,
  UPDATE_APP_SETTINGS_ASYNC_SUCCESS: null,
  UPDATE_APP_SETTINGS_ASYNC_ERROR: null,
  GET_TABLE_SNAPSHOTS_ASYNC_SUCCESS: null,
  GET_TABLE_SNAPSHOTS_ASYNC_ERROR: null,
  UPDATE_TABLE_SNAPSHOT_ASYNC_SUCCESS: null,
  UPDATE_TABLE_SNAPSHOT_ASYNC_ERROR: null,
});

const parseDataGridSnapshots = payload => {
  if (!payload?.length) return {};

  return payload.reduce((acc, { tableName, timestamp, configName, snapshot }) => {
    if (!acc.hasOwnProperty(tableName)) {
      acc[tableName] = {
        list: [{ timestamp, configName, snapshot }],
        lastConfig: { timestamp, configName, snapshot },
      };
    } else {
      acc[tableName].list.push({ timestamp, configName, snapshot });

      if (timestamp > acc[tableName].lastConfig.timestamp) {
        acc[tableName].lastConfig = { timestamp, configName, snapshot };
      }
    }

    return acc;
  }, {});
};

const actions = {
  getTableSnapshot: table => (dispatch, getState, api) => {
    return api.General.getTableSnapshot(table).then(
      payload =>
        dispatch({
          type: actionTypes.GET_TABLE_SNAPSHOTS_ASYNC_SUCCESS,
          payload: parseDataGridSnapshots(payload),
        }),
      error => {
        dispatch({ type: actionTypes.GET_TABLE_SNAPSHOTS_ASYNC_ERROR });
        const message = JSON.stringify(error?.error || error?.message || error);
        dispatch(snackbarActions.openSnackbar(message));
      },
    );
  },

  updateTableSnapshot: (tableName, data) => (dispatch, getState, api) => {
    return api.General.updateTableSnapshot(tableName, data).then(
      payload =>
        dispatch({
          type: actionTypes.UPDATE_TABLE_SNAPSHOT_ASYNC_SUCCESS,
          payload: parseDataGridSnapshots(payload),
        }),
      error => {
        dispatch({ type: actionTypes.UPDATE_TABLE_SNAPSHOT_ASYNC_ERROR });
        const message = JSON.stringify(error?.error || error?.message || error);
        dispatch(snackbarActions.openSnackbar(message));
      },
    );
  },

  setRowModesModel: data => dispatch => {
    dispatch({ type: actionTypes.SET_ROW_MODES_MODEL, payload: data });
  },

  setRowEdit: (id, row) => (dispatch, getState) => {
    const rowModesModel = getState()?.general?.locationsTable?.rowModesModel ?? {};
    const newRowModesModel = {};
    for (const [key, value] of Object.entries(rowModesModel)) {
      newRowModesModel[key] = { ...value, mode: 'view', ignoreModifications: true };
    }
    dispatch(actions.setRowModesModel({ ...newRowModesModel, [id]: { mode: 'edit', row } }));
  },

  stopRowEdit: id => (dispatch, getState) => {
    const rowModesModel = getState()?.general?.locationsTable?.rowModesModel ?? {};
    dispatch(
      actions.setRowModesModel({ ...rowModesModel, [id]: { ...rowModesModel[id], mode: 'view' } }),
    );
  },

  cancelRowEdit: id => (dispatch, getState) => {
    const rowModesModel = getState()?.general?.locationsTable?.rowModesModel ?? {};
    dispatch(
      actions.setRowModesModel({
        ...rowModesModel,
        [id]: { ...rowModesModel[id], mode: 'view', ignoreModifications: true },
      }),
    );
  },

  newLocationFieldCheck: data => dispatch => {
    dispatch({ type: actionTypes.NEW_LOCATION_FIELD_CHANGE, payload: data });
  },

  mobileTableInputChange: data => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.SET_MOBILE_INPUT, payload: data });
  },

  toggleIsVisibleAddNew: data => dispatch => {
    dispatch({ type: actionTypes.IS_VISIBLE_ADD_NEW, payload: data.isVisibleAddNew });
  },

  toggleIsVisibleDeleteDialog: data => dispatch => {
    dispatch({ type: actionTypes.IS_VISIBLE_DELETE, payload: data.deleted_id });
  },

  setSearchValue: data => dispatch => {
    dispatch({ type: actionTypes.SET_SEARCH_VALUE, payload: data.search });
  },

  getLocations: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_LOCATIONS_ASYNC_START });
    return api.General.getLocations().then(
      payload => dispatch({ type: actionTypes.GET_LOCATIONS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_LOCATIONS_ASYNC_ERROR, error }),
    );
  },

  getStatuses: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_STATUSES_ASYNC_START });
    return api.General.getStatuses().then(
      payload => dispatch({ type: actionTypes.GET_STATUSES_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_STATUSES_ASYNC_ERROR, error }),
    );
  },

  refreshLocations: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.IS_VISIBLE_ADD_NEW, payload: false });
    const {
      general: { locationsQueryParams },
    } = getState();
    return api.General.getLocationsWithUsers(locationsQueryParams).then(
      payload => dispatch({ type: actionTypes.GET_LOCATIONS_W_USERS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_LOCATIONS_W_USERS_ASYNC_ERROR, error }),
    );
  },

  getLocationsWithUsers: params => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_LOCATIONS_W_USERS_ASYNC_START });
    return api.General.getLocationsWithUsers(params).then(
      payload => {
        const rowModesModel = getState()?.general?.locationsTable?.rowModesModel ?? {};
        const newRowModesModel = {};
        for (const [key, value] of Object.entries(rowModesModel)) {
          newRowModesModel[key] = { ...value, mode: 'view', ignoreModifications: true };
        }
        dispatch(actions.setRowModesModel({ ...newRowModesModel }));
        dispatch({ type: actionTypes.GET_LOCATIONS_W_USERS_ASYNC_SUCCESS, payload });
      },
      error => dispatch({ type: actionTypes.GET_LOCATIONS_W_USERS_ASYNC_ERROR, error }),
    );
  },

  setActiveLocationType: data => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.SET_ACTIVE_LOCATION_TYPE, payload: data.type });
    return api.General.getLocationsWithUsers({ type: data.type }).then(
      payload => dispatch({ type: actionTypes.GET_LOCATIONS_W_USERS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_LOCATIONS_W_USERS_ASYNC_ERROR, error }),
    );
  },

  addLocation: data => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.ADD_LOCATION_ASYNC_START });
    return api.General.addLocation(data).then(
      success => {
        dispatch(actions.refreshLocations());
        dispatch(snackbarActions.openSnackbar('pages.locations.location_was_added', 'success'));
      },
      error => {
        const message = JSON.stringify(error?.error || error?.message || error);
        dispatch(snackbarActions.openSnackbar(message));
      },
    );
  },

  updateLocation: (id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_LOCATION_ASYNC_START });
    return api.General.updateLocation(id, data).then(
      payload => {
        dispatch({ type: actionTypes.UPDATE_LOCATION_ASYNC_SUCCESS, payload });
        dispatch(actions.refreshLocations());
        const rowModesModel = getState()?.general?.locationsTable?.rowModesModel ?? {};
        dispatch(
          actions.setRowModesModel({
            ...rowModesModel,
            [id]: { ...rowModesModel[id], mode: 'view', ignoreModifications: true },
          }),
        );
        dispatch(snackbarActions.openSnackbar('pages.locations.location_was_updated', 'success'));
      },
      error => {
        const message = JSON.stringify(error?.error || error?.message || error);
        dispatch(snackbarActions.openSnackbar(message));
      },
    );
  },

  deleteLocation: id => (dispatch, getState, api) => {
    return api.General.deleteLocation(id).then(
      success => {
        dispatch(snackbarActions.openSnackbar('pages.locations.location_was_deleted', 'success'));
        dispatch(actions.refreshLocations());
      },
      error => {
        dispatch(snackbarActions.openSnackbar(error));
        dispatch({ type: actionTypes.DELETE_LOCATION_ERROR, error });
      },
    );
  },

  getSalesAgents: () => (dispatch, _, api) =>
    api.General.getSalesAgents().then(
      payload => dispatch({ type: actionTypes.GET_SALES_AGENTS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_SALES_AGENTS_ASYNC_ERROR, error }),
    ),

  getCallAgents: () => (dispatch, _, api) =>
    api.General.getCallAgents().then(
      payload => dispatch({ type: actionTypes.GET_CALL_AGENTS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_CALL_AGENTS_ASYNC_ERROR, error }),
    ),

  getAppSettings: () => (dispatch, getState, api) =>
    api.General.getAppSettings().then(
      payload => dispatch({ type: actionTypes.GET_APP_SETTINGS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_APP_SETTINGS_ASYNC_ERROR, error }),
    ),

  updateAppSettings: data => (dispatch, getState, api) =>
    api.General.updateAppSettings(data).then(
      payload => dispatch({ type: actionTypes.UPDATE_APP_SETTINGS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.UPDATE_APP_SETTINGS_ASYNC_ERROR, error }),
    ),
};

export default actions;
