import { combineReducers } from 'redux';

import generalReducer from './general';
import authReducer from './auth';
import userReducer from './user';
import usersReducer from './users';
import presentationReducer from './presentation';
import presentationsReducer from './presentations';
import recommendationsReducer from './recommendations';
import productsReducer from './products';
import academyReducer from './academy';
import accountsReducer from './accounts';
import clientsReducer from './clients';
import snackbarReducer from './snackbar';
import statisticReducer from './statistic';
import importReducer from './import';
import partnerReducer from './partner';
import calendarReducer from './calendar';
import eventsReducer from './events';
import eventReducer from './event';
import plannerReducer from './planner';
import addressValidationReducer from './addressValidation';
import licenseReducer from './license';
import financesReducer from './finances';

export default combineReducers({
  import: importReducer,
  auth: authReducer,
  user: userReducer,
  users: usersReducer,
  general: generalReducer,
  products: productsReducer,
  presentation: presentationReducer,
  presentations: presentationsReducer,
  recommendations: recommendationsReducer,
  academy: academyReducer,
  accounts: accountsReducer,
  clients: clientsReducer,
  snackbar: snackbarReducer,
  statistic: statisticReducer,
  partner: partnerReducer,
  calendar: calendarReducer,
  events: eventsReducer,
  event: eventReducer,
  planner: plannerReducer,
  addressValidation: addressValidationReducer,
  license: licenseReducer,
  finances: financesReducer,
});
