import { actionTypes } from '../actions/calendar';

const initialState = {
  presentationData: null,
  mode: '', // create, update, move(cancel),
  comment: '',
  isDialogOpen: false,
  isLoading: false,
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_EVENT_START:
    case actionTypes.UPDATE_EVENT_START:
    case actionTypes.MOVE_EVENT_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.CREATE_EVENT_SUCCESS:
    case actionTypes.UPDATE_EVENT_SUCCESS:
    case actionTypes.MOVE_EVENT_SUCCESS:
    case actionTypes.UPDATE_EVENT_ERROR:
    case actionTypes.MOVE_EVENT_ERROR:
    case actionTypes.CLOSE_DIALOG:
      return {
        ...initialState,
        isLoading: false,
      };
    case actionTypes.OPEN_DIALOG: {
      return {
        ...state,
        isDialogOpen: true,
        presentationData: action.presentationData,
        mode: action.mode,
      };
    }
    case actionTypes.CHANGE_COMMENT:
      return {
        ...state,
        comment: action.value,
      };
    default:
      return state;
  }
};

export default calendarReducer;
