import { get, cloneDeep } from 'lodash';
import { actionTypes } from '../actions/user';
import { INITIAL_USER_STATE, SHOW_USER_TABS_KEYS } from '../../constants';

const initialState = {
  data: INITIAL_USER_STATE,
  errors: {},
  isEdited: false,
  mode: 'view',
  currentTab: SHOW_USER_TABS_KEYS.SHOW_USER,
  initialValues: cloneDeep(INITIAL_USER_STATE),
  // virtual/aggregation fields:
  recommendationsCounters: {
    totalRec: 0, // total N of recommendations that user need to give
    paidRec: 0, // N of recommendations that was manually repaid by call agent
    approved: 0, // recommended user is counted like approved when he has successful presentation
    declined: 0,
    confirmed: 0, // USERS_REC, POTENTIAL
    unconfirmed: 0,
    pending: 0, // CALL_AGENTS, SALES_AGENTS
  },
  // user products (used only in user dashboard)
  presentationsWithProducts: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_SUB_USER: {
      return {
        ...state,
        mode: 'edit',
        data: {
          ...state.data,
          sub_user: {
            name: '',
            surname: '',
            age: '',
            zipcode: state.data.zipcode || '',
            city: state.data.city || '',
            address: state.data.address || '',
            phone: '',
            mobile_phone: '',
            email: '',
            company: '',
          },
        },
      };
    }

    case actionTypes.CHANGE_MODE: {
      const { mode } = action;

      return {
        ...state,
        mode,
      };
    }

    case actionTypes.ADD_USER_INFO_SUCCESS: {
      const {
        payload: { data },
      } = action;
      return {
        ...state,
        data: {
          ...state.data,
          user_info: [data, ...state.data.user_info],
        },
      };
    }
    case actionTypes.DELETE_USER_INFO_SUCCESS: {
      const user_info = get(action, 'payload.data.user_info', null);

      return user_info
        ? {
            ...state,
            data: {
              ...state.data,
              user_info,
            },
          }
        : state;
    }
    case actionTypes.GET_USER_ASYNC_SUCCESS: {
      const { payload } = action;
      const data = {
        ...state.data,
        ...payload,
        sub_user: payload.sub_user || initialState.data.sub_user,
      };
      return {
        ...state,
        isEdited: false,
        data,
        initialValues: cloneDeep(data),
        errors: {},
      };
    }
    case actionTypes.GET_USER_ASYNC_ERROR:
      return {
        ...state,
        data: null,
      };
    case actionTypes.EDIT_USER_ASYNC_SUCCESS: {
      const data = {
        ...state.data,
        ...action.payload,
        sub_user: action.payload.sub_user || initialState.data.sub_user,
      };

      return {
        ...state,
        isEdited: false,
        data,
        initialValues: cloneDeep(data),
        errors: {},
      };
    }
    case actionTypes.DELETE_USER_INFO_ERROR:
      return state;
    case actionTypes.USER_DRAFT_CHANGE: {
      const { data, errors } = action.changes;
      return {
        ...state,
        data: {
          ...state.data,
          ...data,
        },
        errors: {
          ...errors,
        },
        isEdited: true,
      };
    }
    case actionTypes.DISCARD_STATE:
      return {
        ...initialState,
      };
    case actionTypes.USER_DRAFT_RESET:
      return {
        ...state,
        data: cloneDeep(state.initialValues),
        errors: {},
        isEdited: false,
      };
    case actionTypes.CREATE_USER_ASYNC_SUCCESS: {
      return {
        ...state,
        data: cloneDeep(state.initialValues),
        errors: {},
        isEdited: false,
      };
    }
    case actionTypes.EDIT_USER_ASYNC_ERROR:
    case actionTypes.CREATE_USER_ASYNC_ERROR: {
      const { error } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...(error?.field_validation && { ...error.field_validation }),
        },
      };
    }
    case actionTypes.GET_USER_REC_COUNT_ASYNC_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        recommendationsCounters: data,
      };
    }
    case actionTypes.GET_USER_PRODUCTS_ASYNC_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        presentationsWithProducts: data,
      };
    }

    case actionTypes.CHANGE_CURRENT_TAB: {
      const { currentTab } = action;
      return {
        ...state,
        currentTab,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
