import React, { Component } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import initializeStore from './store';
import { INITIAL_USER_STATE } from './constants';

const isServer = typeof window === 'undefined';
const storeKey = '__NEXT_REDUX_STORE__';

function initStore(initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    // console.log('\n initialized new data container (on SERVER)');
    return initializeStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!(storeKey in window)) {
    // console.log('\n initialized new data container (on CLIENT)');
    window[storeKey] = initializeStore(initialState);
  }
  return window[storeKey];
}

/**
 * Get current user data from context
 * @param ctx
 */
function getAuthProps(ctx) {
  const currentUser = ctx.req ? ctx.req.user : INITIAL_USER_STATE;
  return {
    currentUser,
    initialValues: cloneDeep(currentUser),
    errors: {},
    isEdited: false,
  };
}

const withReduxStore = App =>
  class AppWithRedux extends Component {
    static async getInitialProps(appContext) {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const reduxStore = initStore();

      // Provide the store to getInitialProps of pages (to be able to invoke dispatch from getInitialProps)
      appContext.ctx.reduxStore = reduxStore;

      // Initialize store with current user data, which we get in req.
      const auth = getAuthProps(appContext.ctx);

      const appProps =
        typeof App.getInitialProps === 'function' ? await App.getInitialProps(appContext) : {};

      return {
        ...appProps,
        initialReduxState: {
          ...reduxStore.getState(),
          auth,
        },
      };
    }

    constructor(props) {
      super(props);
      this.reduxStore = initStore(props.initialReduxState);
    }

    render() {
      return <App {...this.props} reduxStore={this.reduxStore} />;
    }
  };

export default withReduxStore;
