import keyMirror from 'key-mirror';

import snackbarActions from './snackbar';

export const actionTypes = keyMirror({
  PROCESS_ADDRESS_ASYNC_START: null,
  PROCESS_ADDRESS_ASYNC_SUCCESS: null,
  PROCESS_ADDRESS_ASYNC_ERROR: null,
  RESET_ADDRESS_VALIDATION_STATE: null,
});

const initialData = {
  user_id: null,
  locality: '',
  postal_code: '',
  route: '',
  street_number: '',
};

const parseAddressData = data => {
  if (!data?.id) return null;

  const { address = '', id: user_id, zipcode = '', city = '' } = data;
  if (!address && !zipcode && !city) return null;

  const addressData = {
    ...initialData,
    user_id,
    ...(city && { locality: city }),
    ...(zipcode && { postal_code: zipcode }),
  };

  if (!address) return addressData;

  // parse street and street N
  const addressParts = address.match(/\S+\d*/g);
  if (addressParts.length === 1) {
    return {
      ...addressData,
      route: addressParts[0],
    };
  }

  const street_number = addressParts.pop();
  return {
    ...addressData,
    street_number,
    route: addressParts.join(' '),
  };
};

const getValidationSeverity = (verdict, components) => {
  const { addressComplete, hasInferredComponents, hasUnconfirmedComponents } = verdict;
  if (addressComplete && !hasInferredComponents && !hasUnconfirmedComponents) return 'success';

  if (addressComplete && (hasInferredComponents || components.spellCorrectedEl.length > 0))
    return 'info';

  if (components.hasWrongEl || components.unconfirmedEl.length >= 3) return 'error';

  return 'warning';
};

const processValidationVerdict = (verdict, { addressComponents }) => {
  const components = addressComponents.reduce(
    (acc, element) => {
      const { confirmationLevel, spellCorrected, inferred } = element;
      if (confirmationLevel === 'CONFIRMED') {
        if (spellCorrected) acc.spellCorrectedEl.push(element);
        if (inferred) acc.inferredEl.push(element);
        return acc;
      }

      if (confirmationLevel === 'UNCONFIRMED_AND_SUSPICIOUS') acc.hasWrongEl = true;

      acc.unconfirmedEl.push(element);

      return acc;
    },
    {
      hasWrongEl: false,
      unconfirmedEl: [],
      inferredEl: [],
      spellCorrectedEl: [],
    },
  );

  const severity = getValidationSeverity(verdict, components);

  return { severity, ...components };
};

export default {
  resetState: () => dispatch => dispatch({ type: actionTypes.RESET_ADDRESS_VALIDATION_STATE }),
  processAddress: data => async (dispatch, getState, api) => {
    const parsedAddress = parseAddressData(data);
    if (!parsedAddress) {
      const message = 'container.address_validation.errors.address_empty';
      dispatch({ type: actionTypes.PROCESS_ADDRESS_ASYNC_ERROR, error: message });
      dispatch(snackbarActions.openSnackbar(message));
      return { verdict: null, severity: 'error' };
    }

    dispatch({ type: actionTypes.PROCESS_ADDRESS_ASYNC_START, data: parsedAddress });
    try {
      const { address, verdict, geocode } = await api.AddressValidation.processAddress(data);
      const validationVerdict = processValidationVerdict(verdict, address);
      dispatch({
        type: actionTypes.PROCESS_ADDRESS_ASYNC_SUCCESS,
        validationVerdict,
        validationResult: { ...address, geocode },
      });
      return validationVerdict;
    } catch (err) {
      const error = err?.error || err?.message || err;
      const message = typeof error === 'string' ? error : JSON.stringify(error);
      dispatch({ type: actionTypes.PROCESS_ADDRESS_ASYNC_ERROR, error: message });
      dispatch(snackbarActions.openSnackbar(message));
      return error;
    }
  },
};
