import keyMirror from 'key-mirror';

export const actionTypes = keyMirror({
  GET_EVENTS_UPCOMING_ASYNC_START: null,
  GET_EVENTS_UPCOMING_ASYNC_SUCCESS: null,
  GET_EVENTS_UPCOMING_ASYNC_ERROR: null,
  GET_EVENTS_HISTORY_ASYNC_START: null,
  GET_EVENTS_HISTORY_ASYNC_SUCCESS: null,
  GET_EVENTS_HISTORY_ASYNC_ERROR: null,
  EVENTS_CHANGE_FILTER: null,
  EVENTS_CHANGE_SORTED: null,
  EVENTS_CHANGE_PAGE: null,
  EVENTS_CHANGE_PAGINATION: null,
  EVENTS_CHANGE_TAB: null,
});

export default {
  changeTab: tab => dispatch => dispatch({ type: actionTypes.EVENTS_CHANGE_TAB, tab }),
  changeFilter: filter => dispatch =>
    dispatch({ type: actionTypes.EVENTS_CHANGE_FILTER, payload: filter }),
  changeSorted: sorted => dispatch => dispatch({ type: actionTypes.EVENTS_CHANGE_SORTED, sorted }),
  changePage: pageIndex => dispatch =>
    dispatch({ type: actionTypes.EVENTS_CHANGE_PAGE, pageIndex }),
  changePagination: payload => dispatch =>
    dispatch({ type: actionTypes.EVENTS_CHANGE_PAGINATION, payload }),
  getEventsUpcoming:
    (params = {}) =>
    (dispatch, getState, api) => {
      const {
        events: {
          offset,
          limit,
          currentTab,
          filters: { sorted, ...rest },
        },
      } = getState();

      if (sorted.length) {
        const [sortedColumn] = sorted;
        params.orderBy = sortedColumn.id;
        params.orderMethod = sortedColumn.desc ? 'desc' : 'asc';
      }

      const mergedParams = {
        ...params,
        ...rest,
        limit,
        offset,
        eventType: currentTab,
      };

      dispatch({ type: actionTypes.GET_EVENTS_UPCOMING_ASYNC_START });

      return api.Events.getEventsUpcoming(mergedParams).then(
        payload => dispatch({ type: actionTypes.GET_EVENTS_UPCOMING_ASYNC_SUCCESS, payload }),
        error => dispatch({ type: actionTypes.GET_EVENTS_UPCOMING_ASYNC_ERROR, error }),
      );
    },
  getEventsHistory:
    (params = {}) =>
    (dispatch, getState, api) => {
      const {
        events: {
          offset,
          limit,
          currentTab,
          filters: { sorted, ...rest },
        },
      } = getState();
      if (sorted.length) {
        const [sortedColumn] = sorted;
        params.orderBy = sortedColumn.id;
        params.orderMethod = sortedColumn.desc ? 'desc' : 'asc';
      }

      const mergedParams = {
        ...params,
        ...rest,
        limit,
        offset,
        eventType: currentTab,
      };

      dispatch({ type: actionTypes.GET_EVENTS_HISTORY_ASYNC_START });

      return api.Events.getEventsHistory(mergedParams).then(
        payload => dispatch({ type: actionTypes.GET_EVENTS_HISTORY_ASYNC_SUCCESS, payload }),
        error => dispatch({ type: actionTypes.GET_EVENTS_HISTORY_ASYNC_ERROR, error }),
      );
    },
};
