import moment from 'moment';
import { get } from 'lodash';

import { actionTypes } from '../actions/event';
import { actionTypes as eventsActionTypes } from '../actions/events';
import {
  PRESENTATION_STEPS_KEYS,
  DEMO_OUTCOME_KEYS,
  PRESENTATION_STATUS,
  PRODUCT_TYPE,
} from '../../constants';

import { replaceAt } from '../../utils';

export const initialState = {
  data: {
    was_at_home: null,
    recall_time: null,
    order: null,
    user: {},
  },
  drafts: {
    was_at_home: null,
    demo_outcome: null,
    recall_time: null,
    remeet_time: null,
    has_order: null,
    quality: null,
    bad_quality_reason: [],
    bad_quality_reason_desc: '',
    got_gift: null,
    gift: '',
    rej_gift_reason_desc: [],
    saw_presentation: null,
    dont_saw_reason: [],
    dont_saw_reason_desc: '',
    bought_product: null,
    bought_other_products: null,
    dont_buy_reason: [],
    discount_rec_sum: null,
    discount_rec_count: null,
    payment_method: null,
    contract_number: '',
    bank_processing_commission: 0,
    comments: '',
    order_products: [],
    user: {},
    sub_user: null,
    user_documents: [],
    first_payment_month: '',
    payment_installments: null,
  },
  draftsErrors: [],
  eventType: null,
  completedSteps: [],
  checkedSteps: [PRESENTATION_STEPS_KEYS.OVERVIEW],
  currentStep: PRESENTATION_STEPS_KEYS.OVERVIEW,
  isLoading: false,
  isReminderDialogOpen: false,
  error: null,
};

const getCompletedSteps = data => {
  const { status, presentation_products } = data;

  if (status !== PRESENTATION_STATUS.SUCCESS) return [PRESENTATION_STEPS_KEYS.OVERVIEW];

  return presentation_products?.length > 0
    ? Object.keys(PRESENTATION_STEPS_KEYS)
    : [PRESENTATION_STEPS_KEYS.OVERVIEW, PRESENTATION_STEPS_KEYS.USER_PROFILE];
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actionTypes.GET_EVENT_DATA_ASYNC_START:
      return {
        ...initialState,
        eventType: action.eventType,
        isLoading: true,
      };
    case actionTypes.GET_EVENT_DATA_ASYNC_ERROR:
    case actionTypes.PERFORM_EVENT_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case actionTypes.PERFORM_EVENT_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.EVENT_RESET_STATE:
    case actionTypes.PERFORM_EVENT_SUCCESS:
      return initialState;
    case actionTypes.GET_EVENT_DATA_ASYNC_SUCCESS: {
      const { payload } = action;
      const {
        user: { user_documents, sub_user },
      } = payload;

      const { status } = payload;

      if (status === PRESENTATION_STATUS.PLANNED) {
        return {
          ...state,
          drafts: {
            ...initialState.drafts,
            user_documents,
            user: payload.user,
            sub_user: sub_user || null,
          },
          currentStep: PRESENTATION_STEPS_KEYS.OVERVIEW,
          data: payload,
          error: null,
          isLoading: false,
        };
      }

      const hasMP = payload.presentation_products?.some(
        ({ product }) => product.type === PRODUCT_TYPE.MAIN,
      );

      return {
        ...state,
        drafts: {
          ...payload,
          bad_quality_reason: payload.bad_quality_reason
            ? payload.bad_quality_reason.split(',')
            : [],
          dont_saw_reason: payload.dont_saw_reason ? payload.dont_saw_reason.split(',') : [],
          dont_buy_reason: payload.dont_buy_reason ? payload.dont_buy_reason.split(',') : [],
          rej_gift_reason_desc: payload.rej_gift_reason_desc
            ? payload.rej_gift_reason_desc.split(',')
            : [],
          was_at_home: payload.was_at_home === null ? null : !!payload.was_at_home,
          saw_presentation: payload.saw_presentation === null ? null : !!payload.saw_presentation,
          demo_outcome:
            status === PRESENTATION_STATUS.CANCELED
              ? DEMO_OUTCOME_KEYS.CANCELED
              : payload.remeet_presentation_id
              ? DEMO_OUTCOME_KEYS.REMEET
              : payload.recall_history_id
              ? DEMO_OUTCOME_KEYS.RECALL
              : null,
          recall_time: payload.recall_history ? moment(payload.recall_history.date) : null,
          remeet_time: payload.remeet_presentation
            ? moment(payload.remeet_presentation.date)
            : null,
          bought_product: payload?.saw_presentation ? hasMP : null,
          bought_other_products: payload?.dont_buy_reason
            ? payload.presentation_products?.length > 0
            : null,
          got_gift: payload.gift,
          order_products: payload.presentation_products,
          user_documents,
          sub_user: sub_user || null,
        },
        completedSteps: getCompletedSteps(payload),
        data: payload,
        error: null,
        isLoading: false,
      };
    }
    case actionTypes.EVENT_CHANGE_STEP: {
      const { step } = action;
      const { checkedSteps } = state;
      return {
        ...state,
        checkedSteps: checkedSteps.includes(step) ? checkedSteps : [...checkedSteps, step],
        currentStep: step,
      };
    }
    case eventsActionTypes.EVENTS_CHANGE_TAB: {
      return {
        ...initialState,
      };
    }
    case actionTypes.EVENT_CHANGE_DRAFTS_ERRORS: {
      return {
        ...state,
        draftsErrors: action.errors,
      };
    }
    case actionTypes.EVENT_CHANGE_DRAFTS: {
      const drafts = {
        ...state.drafts,
        ...action.data,
      };

      const errors = state.draftsErrors.filter(
        key => get(drafts, key) === null || get(drafts, key) === '',
      );

      return {
        ...state,
        drafts,
        draftsErrors: errors,
      };
    }
    case actionTypes.EVENT_ADD_ORDER_PRODUCT: {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          order_products: [...state.drafts.order_products, action.product],
        },
      };
    }
    case actionTypes.EVENT_REMOVE_ORDER_PRODUCT: {
      const order_products = state.drafts.order_products.filter(
        ({ id }) => id !== action.order_product_id,
      );

      const hasMainProducts = order_products.some(
        ({ product }) => product.type === PRODUCT_TYPE.MAIN,
      );

      return {
        ...state,
        drafts: {
          ...state.drafts,
          order_products: state.drafts.order_products.filter(
            ({ id }) => id !== action.order_product_id,
          ),
          ...(!hasMainProducts && {
            discount_rec_sum: null,
            discount_rec_count: null,
            payment_method: null,
            contract_number: '',
            bank_processing_commission: 0,
          }),
        },
      };
    }
    case actionTypes.EVENT_CHANGE_ORDER_PRODUCT: {
      const { order_products: oldList } = state.drafts;
      const { order_product_id, data } = action;
      const index = oldList.findIndex(({ id }) => id === order_product_id);
      const updatedData = { ...oldList[index], ...data };
      return {
        ...state,
        drafts: {
          ...state.drafts,
          order_products: replaceAt(oldList, index, updatedData),
        },
      };
    }
    default:
      return state;
  }
};

export default eventsReducer;
