import keyMirror from 'key-mirror';

import snackbarActions from './snackbar';

export const actionTypes = keyMirror({
  GET_AGENT_LICENSE_ASYNC_START: null,
  GET_AGENT_LICENSE_ASYNC_SUCCESS: null,
  GET_AGENT_LICENSE_ASYNC_ERROR: null,
  CREATE_CHECKOUT_ASYNC_START: null,
  CREATE_CHECKOUT_ASYNC_SUCCESS: null,
  CREATE_CHECKOUT_ASYNC_ERROR: null,
  RESET_CHECKOUT_URL: null,
});

export default {
  resetCheckoutUrl: () => dispatch => dispatch({ type: actionTypes.RESET_CHECKOUT_URL }),

  getAgentLicense: agentId => async (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_AGENT_LICENSE_ASYNC_START });
    try {
      const payload = await api.License.getAgentLicense(agentId);
      dispatch({
        type: actionTypes.GET_AGENT_LICENSE_ASYNC_SUCCESS,
        payload,
      });
      return payload;
    } catch (err) {
      const error = err?.error || err?.message || err;
      const message = typeof error === 'string' ? error : JSON.stringify(error);
      dispatch({ type: actionTypes.GET_AGENT_LICENSE_ASYNC_ERROR, error: message });
      dispatch(snackbarActions.openSnackbar(message));
      return error;
    }
  },
  createCheckout: () => async (dispatch, getState, api) => {
    dispatch({ type: actionTypes.CREATE_CHECKOUT_ASYNC_START });

    try {
      const checkoutUrl = await api.License.createCheckout();
      dispatch({
        type: actionTypes.CREATE_CHECKOUT_ASYNC_SUCCESS,
        url: checkoutUrl,
      });
    } catch (err) {
      const error = err?.error || err?.message || err;
      const message = typeof error === 'string' ? error : JSON.stringify(error);
      dispatch({ type: actionTypes.CREATE_CHECKOUT_ASYNC_ERROR, error: message });
      dispatch(snackbarActions.openSnackbar(message));
      return error;
    }
  },
};
