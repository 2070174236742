import cs from 'classnames';
import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import withStyles from '@mui/styles/withStyles';

import { green, red } from '@mui/material/colors';

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = () => ({
  icon: {
    fontSize: 20,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const CustomSnackbar = ({ classes, open, message, variant, handleClose, timeout }) => {
  const Icon = variantIcon[variant];
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={timeout}
      onClose={handleClose}
    >
      <SnackbarContent
        sx={{
          backgroundColor:
            variant === 'success' ? green[600] : variant === 'error' ? red[600] : 'inherit',
        }}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classes.icon} style={{ marginRight: 10 }} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

CustomSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['info', 'success', 'error']),
  className: PropTypes.string,
  timeout: PropTypes.number,
};

CustomSnackbar.defaultProps = {
  className: '',
  variant: 'info',
  timeout: 6000,
};

export default withStyles(styles)(CustomSnackbar);
