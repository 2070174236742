import { createTheme, getContrastRatio } from '@mui/material/styles';
import { purple, green, pink, red, grey, blueGrey, lightGreen, blue } from '@mui/material/colors';

import { slateColor } from '../assets/jss/shared';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1750,
    },
  },
  palette: {
    primary: {
      light: blue[300],
      main: blue[400],
      dark: blue[500],
      contrastText: '#fff',
    },
    secondary: {
      light: pink[300],
      main: pink[400],
      dark: pink[500],
      contrastText: '#fff',
    },
    error: {
      light: red[300],
      main: red[400],
      dark: red[500],
      contrastText: '#fff',
    },
    success: {
      light: green[300],
      main: green[400],
      dark: green[500],
      contrastText: '#fff',
    },
    lightGreen: {
      main: lightGreen[500],
      light: lightGreen[400],
      dark: lightGreen[700],
      contrastText: '#fff',
    },
    violet: {
      main: purple[500],
      light: purple[400],
      dark: purple[700],
      contrastText: '#fff',
    },
    blueGrey: {
      main: blueGrey[800],
      light: blueGrey[700],
      dark: blueGrey[400],
      contrastText: '#fff',
    },
    background: {
      default: grey[200],
    },
    text: { primary: blueGrey[800], secondary: blueGrey[400], light: '#FFF' },
  },
  typography: {
    useNextVariants: true,
    caption: {
      fontWeight: 400,
      fontSize: 14,
    },
    body1: {
      fontWeight: 500,
      fontSize: 16,
    },
    body2: {
      fontWeight: 300,
      fontSize: 16,
    },
    h1: {
      fontSize: 48,
      fontWeight: 700,
    },
    h2: {
      fontWeight: 300,
      fontSize: 22,
    },
    h3: {
      fontWeight: 700,
      fontSize: 18,
    },
    h4: {
      fontWeight: 500,
      fontSize: 18,
    },
    h5: {
      fontWeight: 400,
      fontSize: 14,
    },
    h6: {
      fontWeight: 300,
      fontSize: 14,
    },
  },
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: 600,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: green[700],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: { borderRadius: 5, height: 30 },
        avatar: {
          borderRadius: 5,
          height: '100%',
          width: 30,
          marginLeft: 0,
          marginRight: 0,
          color: '#FFF',
        },
      },
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.8em',
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 4,
      },
    },
    MuiSelect: {
      selectMenu: {
        height: '1.1875em',
      },
    },
    MuiBadge: {
      anchorOriginTopRightRectangle: {
        top: 8,
        right: 8,
      },
      badge: {
        right: 5,
        top: 10,
        height: 32,
        padding: '0 13px',
        fontSize: 12,
        borderRadius: 26,
        backgroundColor: slateColor,
        color: '#FFF',
      },
      dot: {
        height: 34,
        width: 34,
        padding: '0 16px',
        borderRadius: 20,
      },
    },
    MuiDialogContent: {
      root: {
        padding: '25px 25px 25px',
      },
    },
    MuiFormLabel: {
      root: {
        whiteSpace: 'nowrap',
      },
    },
    MuiIcon: {
      fontSizeSmall: 17,
      fontSizeLarge: 50,
    },
    MuiButton: {
      textPrimary: {
        textTransform: 'capitalize',
        fontWeight: 700,
      },
    },
    MuiSvgIcon: {
      colorPrimary: { color: slateColor },
      colorSecondary: {
        color: '#FFFFFF',
      },
    },
    MuiTableRow: {
      root: {
        '&:hover': {
          background: '#efefef',
        },
      },
    },
  },
});

export default theme;
