import keyMirror from 'key-mirror';

// export goes only to the reducer
export const actionTypes = keyMirror({
  GET_RECOMMENDATIONS_ASYNC_START: null,
  GET_RECOMMENDATIONS_ASYNC_SUCCESS: null,
  GET_RECOMMENDATIONS_ASYNC_ERROR: null,
  ADD_POTENTIAL_RECOMMENDATIONS_ASYNC_START: null,
  ADD_POTENTIAL_RECOMMENDATIONS_ASYNC_SUCCESS: null,
  ADD_POTENTIAL_RECOMMENDATIONS_ASYNC_ERROR: null,
});

export default {
  getRecommendations: (id, params) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_RECOMMENDATIONS_ASYNC_START });
    return api.Recommendations.getRecommendations(id, params).then(
      payload => dispatch({ type: actionTypes.GET_RECOMMENDATIONS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_RECOMMENDATIONS_ASYNC_ERROR, error }),
    );
  },

  // OBSOLETE ??
  // addPotentialRecommendations: (data, referrerId) => (dispatch, getState, api) => {
  //   dispatch({ type: actionTypes.ADD_POTENTIAL_RECOMMENDATIONS_ASYNC_START });
  //   return api.Recommendations.addPotentialRecommendations(data, referrerId).then(
  //     payload =>
  //       dispatch({ type: actionTypes.ADD_POTENTIAL_RECOMMENDATIONS_ASYNC_SUCCESS, payload }),
  //     error => dispatch({ type: actionTypes.ADD_POTENTIAL_RECOMMENDATIONS_ASYNC_ERROR, error }),
  //   );
  // },
};
