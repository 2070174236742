import keyMirror from 'key-mirror';

export const actionTypes = keyMirror({
  GET_PRODUCTS_ASYNC_START: null,
  GET_PRODUCTS_ASYNC_SUCCESS: null,
  GET_PRODUCTS_ASYNC_ERROR: null,
});

export default {
  getProducts: params => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_PRODUCTS_ASYNC_START });
    return api.Products.getProducts(params).then(
      payload => dispatch({ type: actionTypes.GET_PRODUCTS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_PRODUCTS_ASYNC_ERROR, error }),
    );
  },
};
