import keyMirror from 'key-mirror';

// export goes only to the reducer
export const actionTypes = keyMirror({
  GET_USERS_ASYNC_START: null,
  GET_USERS_ASYNC_SUCCESS: null,
  GET_USERS_ASYNC_ERROR: null,
  GET_SA_USERS_ASYNC_START: null,
  GET_SA_USERS_ASYNC_SUCCESS: null,
  GET_SA_USERS_ASYNC_ERROR: null,
  RESET_USERS: null,
  BLOCK_ACCOUNT: null,
  UNBLOCK_ACCOUNT: null,
  GET_USERS_WITH_ACCOUNTS_ASYNC_SUCCESS: null,
  GET_USERS_WITH_ACCOUNTS_ASYNC_ERROR: null,
});

export default {
  /**
   * @param params {Object} - parameters for the request.
   * @param params.filters {Object} - filters, if not provided - will be taken from store.
   * In case of error during the
   * @param params.limit {number} - limit, if not provided - will be taken from store
   * @param params.offset {number} - offset, if not provided - will be taken from store
   */
  getUsers: params => (dispatch, getState, api) => {
    const {
      users: { offset, limit },
    } = getState();
    const providedLimit = params?.limit;
    const providedOffset = params?.offset;
    const filters = params?.filters;

    // in case of error during the filters parsing - won't call the request
    if (!filters) {
      return Promise.resolve();
    }
    const qParams = {
      ...params,
      limit: typeof providedLimit === 'number' ? providedLimit : limit,
      offset: typeof providedOffset === 'number' ? providedOffset : offset,
      ...filters,
    };
    dispatch({ type: actionTypes.GET_USERS_ASYNC_START });
    return api.Users.getUsers(qParams).then(
      payload => dispatch({ type: actionTypes.GET_USERS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_USERS_ASYNC_ERROR, error }),
    );
  },
  getUsersWithAccounts: params => (dispatch, getState, api) => {
    return api.Users.getUsersWithAccounts(params).then(
      payload => dispatch({ type: actionTypes.GET_USERS_WITH_ACCOUNTS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_USERS_WITH_ACCOUNTS_ASYNC_ERROR, error }),
    );
  },
  getSalesAgentUsers: params => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_SA_USERS_ASYNC_START });
    return api.Users.getSalesAgentUsers(params).then(
      payload => dispatch({ type: actionTypes.GET_SA_USERS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_SA_USERS_ASYNC_ERROR, error }),
    );
  },
  resetUsers: () => dispatch => dispatch({ type: actionTypes.RESET_USERS }),
  blockAccount: id => (dispatch, getState, api) =>
    api.Users.blockAccount(id).then(payload =>
      dispatch({ type: actionTypes.BLOCK_ACCOUNT, payload }),
    ),
  unblockAccount: id => (dispatch, getState, api) =>
    api.Users.unblockAccount(id).then(payload =>
      dispatch({ type: actionTypes.UNBLOCK_ACCOUNT, payload }),
    ),
};
