import { actionTypes } from '../actions/accounts';

import { replaceAt } from '../../utils';

const initialState = {
  list: [],
  isLoading: false,
  listError: null,
};

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNTS_ASYNC_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_ACCOUNTS_ASYNC_SUCCESS: {
      const {
        payload: { data },
      } = action;
      return {
        ...state,
        list: data,
        isLoading: false,
      };
    }
    case actionTypes.UPDATE_ACCOUNT_ASYNC_START:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.UPDATE_ACCOUNT_ASYNC_SUCCESS: {
      const { list: oldList } = state;
      const {
        payload: { data },
      } = action;
      const index = oldList.findIndex(({ id }) => id === data.id);
      if (index === -1) {
        console.error('Unable to find row index');
        return state;
      }
      return {
        ...state,
        list: replaceAt(oldList, index, data),
        listError: null,
      };
    }
    default:
      return state;
  }
};

export default accountsReducer;
