import socket from './index';
import actions from '../store/actions/clients';
import { ROLE_KEYS, CLIENTS_TABLE_NAME_KEYS } from '../constants';

const handleSubs = ({ reduxStore }) => {
  socket.on('connect', () => {
    const store = reduxStore?.getState();
    const currentUser = store?.auth?.currentUser;

    if (currentUser?.role?.code && currentUser?.role?.code !== ROLE_KEYS.USER) {
      socket.emit('connected_agent', currentUser);
    }
  });

  socket.on('call.answered', payload => {
    const { dispatch } = reduxStore;
    dispatch(actions.setAircallEvent(payload));
  });

  socket.on('call.ended', ({ client }) => {
    const store = reduxStore?.getState();
    const { dispatch } = reduxStore;

    const currentTable = store?.clients.currentTable;
    const fetchedClients = store?.clients?.[currentTable]?.data || [];
    const foundIndex = fetchedClients.findIndex(({ id }) => id === client?.id);
    if (foundIndex === -1) return;

    dispatch(actions.processCallEndedEvent({ client, index: foundIndex }));
  });

  socket.on('client_updated', ({ updatedBy, client }) => {
    const store = reduxStore?.getState();
    const { dispatch } = reduxStore;
    const currentUser = store?.auth?.currentUser;

    if (updatedBy === currentUser?.id) return;

    const currentTable = store?.clients.currentTable;
    const fetchedClients = store?.clients?.[currentTable]?.data || [];
    const foundIndex = fetchedClients.findIndex(({ id }) => id === client?.id);
    if (foundIndex === -1) return;

    dispatch(actions.processClientUpdatedEvent({ client, index: foundIndex }));
  });

  socket.on('listing_updated', ({ deleted_id, inserted }) => {
    const store = reduxStore?.getState();
    const { dispatch } = reduxStore;

    const currentTable = store?.clients.currentTable;
    if (currentTable !== CLIENTS_TABLE_NAME_KEYS.LISTING) return;

    dispatch(actions.processListingUpdatedEvent({ deleted_id, inserted }));
  });
};

export default handleSubs;
