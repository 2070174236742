import { actionTypes } from '../actions/products';
import { PRODUCT_TYPE } from '../../constants';

const initialState = {
  // TODO: split on types as main:
  list: [],
  main: {
    list: [],
    map: null,
  },
  isLoading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCTS_ASYNC_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PRODUCTS_ASYNC_SUCCESS: {
      const {
        payload: { data },
      } = action;
      const mainProducts = data.filter(({ type }) => type === PRODUCT_TYPE.MAIN);
      const mainProductsMap = mainProducts.reduce((acc, { code, id, name, default_price }) => {
        acc[code] = {
          id,
          code,
          name,
          default_price,
        };
        return acc;
      }, {});
      const mainProductsIdMap = mainProducts.reduce((acc, { code, id, name, default_price }) => {
        acc[id] = {
          id,
          code,
          name,
          default_price,
        };
        return acc;
      }, {});
      return {
        ...state,
        isLoading: false,
        list: data,
        main: {
          list: mainProducts,
          map: mainProductsMap,
          idMap: mainProductsIdMap,
        },
      };
    }
    default:
      return state;
  }
};

export default authReducer;
