import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import CustomSnackbar from '../../components/Extended/CustomSnackbar';
import snackbarActions from '../../core/store/actions/snackbar';
import { withTranslation } from '../../i18n';

/**
 * This snackbar container used as a single entry point for global errors
 */
class SnackbarContainer extends Component {
  handleCloseSnackbar = () => {
    const { dispatch } = this.props;
    dispatch(snackbarActions.closeSnackbar());
  };

  render() {
    const { type, message = '', iData, isOpen, t } = this.props;

    const data = !isEmpty(iData)
      ? {
          ...iData,
          ...(iData?.error && { error: t(iData.error) }),
        }
      : null;

    const parsedMessage = typeof message === 'string' ? message : JSON.stringify(message);
    const translatedMsg = t(parsedMessage, data);

    return (
      <CustomSnackbar
        variant={type}
        open={isOpen}
        message={translatedMsg}
        handleClose={this.handleCloseSnackbar}
      />
    );
  }
}

export default compose(
  withTranslation('common'),
  connect(({ snackbar }, ownProps) => ({ ...snackbar, ...ownProps })),
)(SnackbarContainer);
