import keyMirror from 'key-mirror';
import { pick, get } from 'lodash';

import snackbarActions from './snackbar';
import { PRESENTATION_STATUS, EVENT_TYPE_KEYS } from '../../constants';

export const actionTypes = keyMirror({
  CLOSE_DIALOG: null,
  OPEN_DIALOG: null,
  CHANGE_COMMENT: null,
  CREATE_EVENT_START: null,
  CREATE_EVENT_SUCCESS: null,
  UPDATE_EVENT_START: null,
  UPDATE_EVENT_SUCCESS: null,
  MOVE_EVENT_START: null,
  MOVE_EVENT_SUCCESS: null,
  UPDATE_EVENT_ERROR: null,
  MOVE_EVENT_ERROR: null,
});

export default {
  submitEvent: () => (dispatch, getState, api) => {
    const {
      calendar: { mode, presentationData, comment },
    } = getState();
    if (mode === 'create') {
      const { userData, agentId, date, presentationId, eventType, serviceId } = presentationData;

      dispatch({ type: actionTypes.CREATE_EVENT_START });
      return api.Calendar.createEvent({
        userData: pick(userData, [
          'name',
          'surname',
          'lead_source',
          'age',
          'city',
          'address',
          'zipcode',
          'phone',
          'mobile_phone',
          'working_status',
          'relations',
          'persons_at_home',
          'promotional_gift',
          'referral_link',
          'sub_user',
        ]),
        eventType,
        serviceId,
        comment,
        agentId,
        presentationId,
        date,
      }).then(
        payload => {
          const summary = payload.data?.summary;
          const error = payload.data?.error;
          if (error) {
            dispatch(
              snackbarActions.openSnackbar('cmp.snackbar.calendar_event_error', 'error', {
                error: error?.message || error.toString(),
              }),
            );
            dispatch({ type: actionTypes.CREATE_EVENT_SUCCESS, payload });
          } else {
            dispatch(
              snackbarActions.openSnackbar(
                'cmp.snackbar.calendar_event_created_success',
                'success',
                {
                  summary,
                },
              ),
            );
            dispatch({ type: actionTypes.CREATE_EVENT_SUCCESS, payload });
          }
        },
        error => {
          console.log(error);
          dispatch(snackbarActions.openSnackbar(error.message));
        },
      );
    }
    if (mode === 'update') {
      const { eventId, agentId, date, status } = presentationData;
      dispatch({ type: actionTypes.UPDATE_EVENT_START });
      return api.Calendar.updateEvent({
        eventId,
        comment,
        status,
        // we need to update agent (attendee) only for planned presentation.
        ...(status === PRESENTATION_STATUS.PLANNED && {
          agentId,
        }),
        date,
      }).then(
        payload => {
          const summary = payload.data?.summary;
          const error = payload.data?.error;
          if (error) {
            dispatch(
              snackbarActions.openSnackbar('cmp.snackbar.calendar_event_error', 'error', {
                error: error?.message || error.toString(),
              }),
            );
            dispatch({ type: actionTypes.UPDATE_EVENT_SUCCESS, payload });
          } else {
            dispatch(
              snackbarActions.openSnackbar(
                'cmp.snackbar.calendar_event_updated_success',
                'success',
                {
                  summary,
                },
              ),
            );
            dispatch({ type: actionTypes.UPDATE_EVENT_SUCCESS, payload });
          }
        },
        error => {
          const message = get(error, 'data.error.message', 'cmp.snackbar.calendar_event_error');
          dispatch(snackbarActions.openSnackbar(message));
          dispatch({ type: actionTypes.UPDATE_EVENT_ERROR });
        },
      );
    }
    if (mode === 'move') {
      const { eventId, status, date } = presentationData;
      dispatch({ type: actionTypes.MOVE_EVENT_START });
      return api.Calendar.moveEvent({
        eventId,
        status,
        date,
        comment,
      }).then(
        payload => {
          const summary = payload.data?.summary;
          const error = payload.data?.error;
          if (error) {
            dispatch(
              snackbarActions.openSnackbar('cmp.snackbar.calendar_event_error', 'error', {
                error: error?.message || error.toString(),
              }),
            );
            dispatch({ type: actionTypes.MOVE_EVENT_SUCCESS, payload });
          } else {
            dispatch(
              snackbarActions.openSnackbar(
                'cmp.snackbar.calendar_event_updated_success',
                'success',
                {
                  summary,
                },
              ),
            );
            dispatch({ type: actionTypes.MOVE_EVENT_SUCCESS, payload });
          }
        },
        error => {
          const message = get(error, 'data.error.message', 'cmp.snackbar.calendar_event_error');
          dispatch(snackbarActions.openSnackbar(message));
          dispatch({ type: actionTypes.MOVE_EVENT_ERROR });
        },
      );
    }
    console.error('Unknown mode.');
    return null;
  },
  changeComment: value => dispatch => dispatch({ type: actionTypes.CHANGE_COMMENT, value }),
  closeDialog: () => dispatch => dispatch({ type: actionTypes.CLOSE_DIALOG }),
  openDialog: (presentationData, mode) => dispatch =>
    dispatch({ type: actionTypes.OPEN_DIALOG, presentationData, mode }),
};
