import keyMirror from 'key-mirror';

// export goes only to the reducer
export const actionTypes = keyMirror({
  GET_CURRENT_USER_NEEDED_REC_ASYNC_SUCCESS: null,
  GET_CURRENT_USER_NEEDED_REC_ASYNC_ERROR: null,
  GET_CURRENT_USER_ASYNC_START: null,
  GET_CURRENT_USER_ASYNC_SUCCESS: null,
  GET_CURRENT_USER_ASYNC_ERROR: null,
  SET_CURRENT_USER: null,
  EDIT_CURRENT_USER_ASYNC_START: null,
  EDIT_CURRENT_USER_ASYNC_SUCCESS: null,
  EDIT_CURRENT_USER_ASYNC_ERROR: null,
  CURRENT_USER_DRAFT_CHANGE: null,
  CURRENT_USER_RESET_DRAFT: null,
  // actualize means that onboarding products will be created/deleted (or remained the same)
  ACTUALIZE_REC_PRODUCTS_START: null,
  ACTUALIZE_REC_PRODUCTS_SUCCESS: null,
  ACTUALIZE_REC_PRODUCTS_ERROR: null,
  // LOGIN: null,
  // LOGIN_ASYNC_START: null,
  // LOGIN_ASYNC_SUCCESS: null,
  // LOGIN_ASYNC_ERROR: null,
  // SIGNUP: null,
  // SIGNUP_ASYNC_START: null,
  // SIGNUP_ASYNC_SUCCESS: null,
  // SIGNUP_ASYNC_ERROR: null,
  // LOGOUT: null,
});

// TODO: I`ve not decided yet to move auth (login/signup) logic to redux!
// its conveniently that actions encapsulates the api object, actionTypes and dispatch method
export default {
  /**
   * @param {Object} data
   * @param {string} data.password
   * @param {string} data.hash
   */
  // signup: data => (dispatch, getState, api) => {
  //   dispatch({ type: actionTypes.SIGNUP_ASYNC_START });
  //   api.Auth.signup(data).then(
  //     payload => dispatch({ type: actionTypes.SIGNUP_ASYNC_SUCCESS, payload }),
  //     error => dispatch({ type: actionTypes.SIGNUP_ASYNC_ERROR, error }),
  //   );
  // },

  /**
   *
   * @param {Object} data
   * @param {string} data.email
   * @param {string} data.password
   */
  // login: data => (dispatch, getState, api) => {
  //   dispatch({ type: actionTypes.LOGIN_ASYNC_START });
  //   return api.Auth.login(data).then(
  //     payload => dispatch({ type: actionTypes.LOGIN_ASYNC_SUCCESS, payload }),
  //     error => dispatch({ type: actionTypes.LOGIN_ASYNC_ERROR, error }),
  //   );
  // },

  // logout: () => (dispatch, getState, api) => {
  //   dispatch({ type: actionTypes.LOGOUT });
  //   return api.Auth.logout();
  // },

  getCurrentUser: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_CURRENT_USER_ASYNC_START });
    return api.Auth.getCurrentUser().then(
      payload => dispatch({ type: actionTypes.GET_CURRENT_USER_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_CURRENT_USER_ASYNC_ERROR, error }),
    );
  },

  /**
   * Save current user data to the store (F.E after successful login)
   * @param {Object} data - user data
   */
  setCurrentUser: data => dispatch => {
    dispatch({ type: actionTypes.SET_CURRENT_USER, data });
  },

  /**
   * @param {Object} changes
   * @param {Object} changes.data
   * @param {Object} changes.errors
   * @returns {Function}
   */
  handleDraftChange: changes => dispatch => {
    dispatch({ type: actionTypes.CURRENT_USER_DRAFT_CHANGE, changes });
  },

  handleResetDraft: () => dispatch => {
    dispatch({ type: actionTypes.CURRENT_USER_RESET_DRAFT });
  },

  editCurrentUser: data => (dispatch, getState, api) =>
    api.Auth.editCurrentUser(data).then(
      payload => dispatch({ type: actionTypes.EDIT_CURRENT_USER_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.EDIT_CURRENT_USER_ASYNC_ERROR, error }),
    ),

  getCurrentUserNeededRecQuantity: id => (dispatch, getState, api) =>
    api.User.getUserNeededRecQuantity(id).then(
      payload => dispatch({ type: actionTypes.GET_CURRENT_USER_NEEDED_REC_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_CURRENT_USER_NEEDED_REC_ASYNC_ERROR, error }),
    ),

  actualizeUserRecProducts: productIds => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.ACTUALIZE_REC_PRODUCTS_START });
    // api.UserRecProducts.actualizeUserRecProducts(productIds).then(
    //   payload => dispatch({ type: actionTypes.ACTUALIZE_REC_PRODUCTS_SUCCESS, payload }),
    //   error => dispatch({ type: actionTypes.ACTUALIZE_REC_PRODUCTS_ERROR, error }),
    // );
  },
};
