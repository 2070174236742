import keyMirror from 'key-mirror';
import snackbarActions from './snackbar';

export const actionTypes = keyMirror({
  CHANGE_DATA: null,
  NEXT_STEP: null,
  PREV_STEP: null,
  GET_PRESENTATION_ASYNC_START: null,
  GET_PRESENTATION_ASYNC_SUCCESS: null,
  GET_PRESENTATION_ASYNC_ERROR: null,
  UPDATE_PRESENTATION_ASYNC_START: null,
  UPDATE_PRESENTATION_ASYNC_SUCCESS: null,
  UPDATE_PRESENTATION_ASYNC_ERROR: null,
  FINISH_PRESENTATION_ASYNC_START: null,
  FINISH_PRESENTATION_ASYNC_SUCCESS: null,
  FINISH_PRESENTATION_ASYNC_ERROR: null,
  CLEAR_SNACKBAR_MESSAGE: null,
  DELETE_PRESENTATION_ASYNC_START: null,
  DELETE_PRESENTATION_ASYNC_SUCCESS: null,
  DELETE_PRESENTATION_ASYNC_ERROR: null,
});

export default {
  getPresentation: id => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_PRESENTATION_ASYNC_START });
    return api.Presentation.getPresentation(id).then(
      payload => dispatch({ type: actionTypes.GET_PRESENTATION_ASYNC_SUCCESS, payload }),
      payload => {
        dispatch(snackbarActions.openSnackbar(payload.error));
        return dispatch({ type: actionTypes.GET_PRESENTATION_ASYNC_ERROR, payload });
      },
    );
  },

  changeData: data => (dispatch, getState, api) => {},

  updatePresentation: (id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_PRESENTATION_ASYNC_START });
    return api.Presentation.updatePresentation(id, { data }).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.presentation.updated_success', 'success'),
        );
        return dispatch({ type: actionTypes.UPDATE_PRESENTATION_ASYNC_SUCCESS, payload });
      },
      payload => {
        dispatch(snackbarActions.openSnackbar(payload.error));
        return dispatch({ type: actionTypes.UPDATE_PRESENTATION_ASYNC_ERROR, payload });
      },
    );
  },

  deletePresentation: id => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.DELETE_PRESENTATION_ASYNC_START });
    return api.Presentation.deletePresentation(id).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.presentation.deleted_success', 'success'),
        );
        return dispatch({ type: actionTypes.DELETE_PRESENTATION_ASYNC_SUCCESS, payload });
      },
      payload => {
        dispatch(snackbarActions.openSnackbar(payload.error));
        return dispatch({ type: actionTypes.DELETE_PRESENTATION_ASYNC_ERROR, payload });
      },
    );
  },

  nextStep: step => (dispatch, getState, api) => {},

  prevStep: () => (dispatch, getState, api) => {},

  finishPresentation: (id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.FINISH_PRESENTATION_ASYNC_START });
    return api.Presentation.finishPresentation(id, data).then(
      payload => {
        dispatch(snackbarActions.openSnackbar('Presentation saved successfully', 'success'));
        return dispatch({ type: actionTypes.FINISH_PRESENTATION_ASYNC_SUCCESS, payload });
      },
      payload => {
        dispatch(snackbarActions.openSnackbar(payload.error));
        return dispatch({ type: actionTypes.FINISH_PRESENTATION_ASYNC_ERROR, payload });
      },
    );
  },

  clearSnackbarMessage: () => dispatch => dispatch({ type: actionTypes.CLEAR_SNACKBAR_MESSAGE }),
};
