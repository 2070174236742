const get = require('lodash/get');
const NextI18Next = require('next-i18next').default;

const { APP_LANGUAGES } = require('./core/constants');

const DEFAULT_LANG = process.env.NEXT_PUBLIC_DEFAULT_LANG;

const userLanguageDetection = {
  name: 'user-language-detection',
  lookup: (req, i18n) => {
    return get(req, 'user.locale');
  },
};

const otherLanguages = APP_LANGUAGES.filter(lang => lang !== DEFAULT_LANG);

const nextI18NextInstance = new NextI18Next({
  preload: ['de', 'it', 'en'],
  browserLanguageDetection: true,
  serverLanguageDetection: true,
  customDetectors: [userLanguageDetection],
  detection: {
    order: ['user-language-detection', 'cookie'],
    lookupCookie: 'next-i18next',
  },
  ns: ['common'],
  otherLanguages,
  defaultLanguage: DEFAULT_LANG,
  fallbackLng: DEFAULT_LANG,
  fallbackNS: 'common',
  defaultNS: 'common',
});

nextI18NextInstance.i18n.languages = APP_LANGUAGES;

module.exports = nextI18NextInstance;
