import { actionTypes } from '../actions/presentations';

const initialState = {
  list: [],
  offset: 0,
  limit: 20,
  count: 0,
  total: 0,
  isLoading: false,
  error: null,
  page: null,
  filters: {
    statuses: [],
    search: '',
    visible: true,
  },
  counters: {
    future: 0,
    expired: 0,
    successful: 0,
    delivery: 0,
  },
};

const presentationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRESENTATIONS_ASYNC_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PRESENTATIONS_ASYNC_SUCCESS: {
      const {
        payload: { data },
      } = action;
      return {
        ...state,
        list: data,
        error: null,
        isLoading: false,
      };
    }
    case actionTypes.GET_PRESENTATIONS_ASYNC_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case actionTypes.GET_PRESENTATIONS_COUNTERS_ASYNC_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        counters: payload,
      };
    }
    case actionTypes.CHANGE_FILTER_VALUE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.name]: action.value,
        },
      };
    case actionTypes.CHANGE_FILTER_VISIBILITY: {
      const {
        filters: { visible },
      } = state;
      return {
        ...state,
        filters: {
          ...state.filters,
          visible: !visible,
        },
      };
    }
    case actionTypes.RESET_PRESENTATIONS:
      return initialState;
    case actionTypes.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };
    default:
      return state;
  }
};

export default presentationsReducer;
