import keyMirror from 'key-mirror';
import { pick } from 'lodash';
import uuid from 'uuid/v4';

import {
  PRESENTATION_PRODUCT_STATUS_KEYS,
  PRESENTATION_PRODUCT_REPAYMENT_TYPE,
} from '../../constants';
import { EVENT_TYPE_KEYS } from '../../constants';
import snackbarActions from './snackbar';

export const actionTypes = keyMirror({
  GET_EVENT_DATA_ASYNC_START: null,
  GET_EVENT_DATA_ASYNC_SUCCESS: null,
  GET_EVENT_DATA_ASYNC_ERROR: null,
  EVENT_CHANGE_STEP: null,
  EVENT_CHANGE_DRAFTS: null,
  EVENT_CHANGE_DRAFTS_ERRORS: null,
  EVENT_ADD_ORDER_PRODUCT: null,
  EVENT_REMOVE_ORDER_PRODUCT: null,
  EVENT_CHANGE_ORDER_PRODUCT: null,
  PERFORM_EVENT_START: null,
  PERFORM_EVENT_SUCCESS: null,
  PERFORM_EVENT_ERROR: null,
  EVENT_RESET_STATE: null,
  UPDATE_EVENT_START: null,
  UPDATE_EVENT_SUCCESS: null,
  UPDATE_EVENT_ERROR: null,
  SET_IS_LOADING: null,
});

export default {
  setIsLoading: isLoading => dispatch => dispatch({ type: actionTypes.SET_IS_LOADING, isLoading }),

  changeStep: step => dispatch => dispatch({ type: actionTypes.EVENT_CHANGE_STEP, step }),

  getEvent: params => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_EVENT_DATA_ASYNC_START, eventType: params.eventType });

    return api.Event.getEvent(params).then(
      payload => dispatch({ type: actionTypes.GET_EVENT_DATA_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_EVENT_DATA_ASYNC_ERROR, error }),
    );
  },

  resetEventState: () => dispatch => dispatch({ type: actionTypes.EVENT_RESET_STATE }),

  changeDrafts: data => dispatch => dispatch({ type: actionTypes.EVENT_CHANGE_DRAFTS, data }),

  changeDraftsErrors: errors => dispatch =>
    dispatch({ type: actionTypes.EVENT_CHANGE_DRAFTS_ERRORS, errors }),

  addOrderProduct:
    ({ product_id, product, status, rec_price, price, quantity, years_of_guarantee }) =>
    dispatch => {
      const newProduct = {
        // important to have uniq id for component logic. Persistent id will be re-generated on backend
        id: uuid(),
        product_id,
        status,
        repayment_type:
          status === PRESENTATION_PRODUCT_STATUS_KEYS.SELL
            ? PRESENTATION_PRODUCT_REPAYMENT_TYPE.MONEY
            : status === PRESENTATION_PRODUCT_STATUS_KEYS.REC
            ? PRESENTATION_PRODUCT_REPAYMENT_TYPE.RECOMMENDATION
            : null,
        quantity,
        product,
        years_of_guarantee,
        price: product.default_price,
        ...(price && { price }),
        ...(status === PRESENTATION_PRODUCT_STATUS_KEYS.REC && {
          rec_price,
        }),
      };
      dispatch({ type: actionTypes.EVENT_ADD_ORDER_PRODUCT, product: newProduct });
    },

  removeOrderProduct: order_product_id => dispatch =>
    dispatch({ type: actionTypes.EVENT_REMOVE_ORDER_PRODUCT, order_product_id }),

  changeOrderProduct: (order_product_id, data) => dispatch =>
    dispatch({ type: actionTypes.EVENT_CHANGE_ORDER_PRODUCT, order_product_id, data }),

  performEvent: event_id => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.PERFORM_EVENT_START });

    const {
      event: { drafts, eventType },
    } = getState();

    if (eventType === EVENT_TYPE_KEYS.SERVICE) {
      return api.Event.performService(
        event_id,
        pick(drafts, ['was_at_home', 'recall_time', 'has_order', 'order_products']),
      ).then(
        payload => {
          dispatch(snackbarActions.openSnackbar('cmp.snackbar.service_flow_success', 'success'));
          return dispatch({ type: actionTypes.PERFORM_EVENT_SUCCESS, payload });
        },
        error => {
          dispatch(snackbarActions.openSnackbar('cmp.snackbar.service_flow_error', 'error'));
          return dispatch({ type: actionTypes.PERFORM_EVENT_ERROR, error });
        },
      );
    } else {
      return api.Presentation.finishPresentation(event_id, drafts).then(
        payload => {
          dispatch(
            snackbarActions.openSnackbar('cmp.snackbar.presentation_flow_success', 'success'),
          );
          return dispatch({ type: actionTypes.PERFORM_EVENT_SUCCESS, payload });
        },
        error => {
          dispatch(
            snackbarActions.openSnackbar('cmp.snackbar.presentation_flow_error', 'error', {
              error: error.message || JSON.stringify(error),
            }),
          );
          return dispatch({ type: actionTypes.PERFORM_EVENT_ERROR, error });
        },
      );
    }
  },

  updateEvent: event_id => (dispatch, getState, api) => {
    const {
      event: { drafts, eventType },
    } = getState();

    if (eventType === EVENT_TYPE_KEYS.PRESENTATION) {
      dispatch({ type: actionTypes.UPDATE_EVENT_START });

      const data = pick(drafts, [
        'discount_rec_sum',
        'discount_rec_count',
        'comments',
        'contract_number',
      ]);

      return api.Presentation.updatePresentation(event_id, { data }).then(
        payload => {
          dispatch(
            snackbarActions.openSnackbar('cmp.snackbar.presentation.updated_success', 'success'),
          );
          return dispatch({ type: actionTypes.UPDATE_EVENT_SUCCESS, payload });
        },
        error => {
          dispatch(
            snackbarActions.openSnackbar('cmp.snackbar.presentation_flow_error', 'error', {
              error: error.message || JSON.stringify(error),
            }),
          );
          return dispatch({ type: actionTypes.UPDATE_EVENT_ERROR, payload });
        },
      );
    }
  },
};
