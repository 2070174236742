import { actionTypes as recActionTypes } from '../actions/recommendations';
import { actionTypes as userActionTypes } from '../actions/user';
import { replaceAt } from '../../utils';

const initialState = {
  list: [],
  isLoading: false,
  errors: {},
  total: 0,
  limit: 25,
  offset: 0,
};

const recommendationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case recActionTypes.GET_RECOMMENDATIONS_ASYNC_START:
      return {
        ...state,
        list: [],
        isLoading: true,
      };
    case recActionTypes.GET_RECOMMENDATIONS_ASYNC_SUCCESS: {
      const { data, offset, limit, total } = action.payload;
      return {
        ...state,
        list: data,
        offset,
        limit,
        total,
        isLoading: false,
      };
    }
    case recActionTypes.GET_RECOMMENDATIONS_ASYNC_ERROR:
      return {
        ...state,
        errors: action.error,
        list: [],
        isLoading: false,
      };
    case userActionTypes.DELETE_USER_ASYNC_SUCCESS: {
      const {
        payload: { deletedId },
      } = action;
      return {
        ...state,
        list: state.list.filter(({ id }) => id !== deletedId),
        isLoading: false,
      };
    }
    case userActionTypes.EDIT_USER_ASYNC_START:
      return {
        ...state,
        isLoading: true,
      };
    case userActionTypes.EDIT_USER_ASYNC_SUCCESS: {
      const { list: oldList } = state;
      const { payload } = action;
      const { id: updatedId } = payload;
      const index = oldList.findIndex(({ id }) => id === updatedId);
      return {
        ...state,
        isLoading: false,
        list: replaceAt(oldList, index, payload),
      };
    }
    case recActionTypes.ADD_POTENTIAL_RECOMMENDATIONS_ASYNC_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case recActionTypes.ADD_POTENTIAL_RECOMMENDATIONS_ASYNC_SUCCESS: {
      const {
        payload: { data },
      } = action;
      return {
        ...state,
        isLoading: false,
        list: data,
      };
    }
    default:
      return state;
  }
};

export default recommendationsReducer;
