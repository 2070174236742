import keyMirror from 'key-mirror';

export const STATISTIC_TAB_KEYS = keyMirror({
  SALES_AGENT: null,
  CALL_AGENT: null,
});

export const STATISTIC_TAB_LABELS = {
  [STATISTIC_TAB_KEYS.SALES_AGENT]: 'total_statistics.tabs.sales_agents',
  [STATISTIC_TAB_KEYS.CALL_AGENT]: 'total_statistics.tabs.call_agents',
};
