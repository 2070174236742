import { actionTypes } from '../actions/license';

const initialState = {
  isLoading: false,
  error: null,
  // list of all licenses
  list: [],
  // current user license info
  current: {},
  checkoutURL: '',
};

const licenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_CHECKOUT_URL: {
      return {
        ...state,
        error: null,
        checkoutURL: '',
        isLoading: false,
      };
    }
    case actionTypes.CREATE_CHECKOUT_ASYNC_SUCCESS: {
      return {
        ...state,
        error: null,
        checkoutURL: action.url,
        isLoading: false,
      };
    }
    case actionTypes.GET_AGENT_LICENSE_ASYNC_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        current: action.payload,
      };
    }
    case actionTypes.CREATE_CHECKOUT_ASYNC_START:
    case actionTypes.GET_AGENT_LICENSE_ASYNC_START: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.CREATE_CHECKOUT_ASYNC_ERROR:
    case actionTypes.GET_AGENT_LICENSE_ASYNC_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default licenseReducer;
