import cloneDeep from 'lodash/cloneDeep';
import { actionTypes } from '../actions/auth';

import { INITIAL_USER_STATE } from '../../constants';

const initialState = {
  currentUser: INITIAL_USER_STATE,
  // store validation errors for the edit-profile page,
  // {key - field name, value - validation error}
  errors: {},
  // there were some draft changes in current user
  isEdited: false,
  initialValues: cloneDeep(INITIAL_USER_STATE),
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    // TODO: I`ve not decided yet to move auth (login/signup) logic to redux
    // case actionTypes.LOGIN:
    //   return {
    //     ...state,
    //     login: true,
    //   };

    // case actionTypes.SIGNUP:
    //   return {
    //     ...state,
    //     login: true,
    //   };

    case actionTypes.GET_CURRENT_USER_ASYNC_SUCCESS:
      return {
        ...state,
        isEdited: false,
        currentUser: action.payload,
        initialValues: cloneDeep(action.payload),
        errors: {},
      };
    case actionTypes.GET_CURRENT_USER_ASYNC_ERROR:
      return {
        ...state,
        currentUser: null,
      };
    case actionTypes.SET_CURRENT_USER:
      return {
        ...state,
        isEdited: false,
        currentUser: action.data,
        initialValues: cloneDeep(action.data),
        errors: {},
      };
    case actionTypes.EDIT_CURRENT_USER_ASYNC_SUCCESS: {
      const { error } = action.payload;
      if (error) {
        return {
          ...state,
          isEdited: false,
          currentUser: error ? state.currentUser : action.payload,
          initialValues: error ? state.initialValues : cloneDeep(action.payload),
          errors: error || {},
        };
      }
      return {
        ...state,
        isEdited: false,
        currentUser: { ...state.currentUser, ...action.payload },
        initialValues: error ? state.initialValues : cloneDeep(action.payload),
        errors: error || {},
      };
    }
    case actionTypes.EDIT_CURRENT_USER_ASYNC_ERROR:
      return state;
    case actionTypes.CURRENT_USER_DRAFT_CHANGE: {
      const { data, errors } = action.changes;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...data,
        },
        errors: {
          ...state.errors,
          ...errors,
        },
        isEdited: true,
      };
    }
    case actionTypes.CURRENT_USER_RESET_DRAFT:
      return {
        ...state,
        currentUser: cloneDeep(state.initialValues),
        errors: {},
        isEdited: false,
      };
    case actionTypes.GET_CURRENT_USER_NEEDED_REC_ASYNC_SUCCESS: {
      const { result } = action.payload;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          recommendations_needed: result,
        },
      };
    }
    case actionTypes.ACTUALIZE_REC_PRODUCTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          recommendation_products: data,
        },
      };
    }
    default:
      return state;
  }
};

export default authReducer;
