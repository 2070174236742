/**
 * THIS UTILS ARE ONLY FOR CLIENT PART
 */
import moment from 'moment';
import { cloneDeep, isEmpty } from 'lodash';

import { green, red, lime, deepOrange, grey } from '@mui/material/colors';

import {
  DAY_PHASES,
  WORKING_STATUSES,
  CONTACT_HISTORY_TYPES,
  PRESENTATION_STATUS,
  ORDER_STATUS_KEYS,
  AGENT_COMMISSION_STATUS_KEYS,
  ORDER_DELIVERY_STATUS_KEYS,
  ORDER_PAYMENT_STATUS_KEYS,
  AGENT_COMMISSION_RECEIPT_STATUS_KEYS,
} from '../constants';

import { GUID_REGEXP } from '../regexp';

export const getRootUrl = () => process.env.NEXT_PUBLIC_HOST;

export const replaceAt = (array, index, value) => {
  const copy = cloneDeep(array);
  copy[index] = value;
  return copy;
};

export const isGuid = string => GUID_REGEXP.test(string);

export const getDisplayWorkingStatus = (code, t) => {
  switch (code) {
    case WORKING_STATUSES.FULL_TIME:
      return '100%';
    case WORKING_STATUSES.PART_TIME:
      return '50%';
    case WORKING_STATUSES.RETIREE:
      return t('cnst.working_statuses.retiree');
    case WORKING_STATUSES.SEARCH:
      return t('cnst.working_statuses.search');
    default:
      return '';
  }
};

export const capitalize = string =>
  typeof string === 'string' && string
    ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    : '';

const MORNING = {
  START: '08:00:00',
  END: '12:59:59',
};

const DAY = {
  START: '13:00:00',
  END: '17:59:59',
};

const NIGHT = {
  START: '18:00:00',
  END: '07:59:59',
};

/**
 * determine day phase by given time (utc):
 * @param {string|date} date
 * @returns {string} - oneOf(DAY_PHASES)
 */
export const getDayPhase = date => {
  const timeString =
    typeof date === 'string'
      ? new Date(date).toTimeString().slice(0, 8)
      : moment(date).format().slice(0, 8);

  if (timeString >= MORNING.START && timeString <= MORNING.END) {
    return DAY_PHASES.MORNING;
  }
  if (timeString >= DAY.START && timeString <= DAY.END) {
    return DAY_PHASES.DAY;
  }
  if ((timeString >= NIGHT.START && timeString <= '23:59:59') || timeString <= NIGHT.END) {
    return DAY_PHASES.NIGHT;
  }

  console.warn('unable to determine day phase');
  return DAY_PHASES.NIGHT;
};

/**
 * calculate and return how much time left from initialDate to current date(now)
 * in given format:
 * n Days nn:nn Hours (if time diff is lesser then provided in hasDays)
 * - n Days nn:nn Hours (if time diff is greater then provided in hasDays)
 * @param {string|date} initialDate
 * @param {number} [hasDays=7]
 * @returns {Object}
 */
export const getRecommendationCountdown = (initialDate, hasDays = 7) => {
  if (!initialDate) return null;
  const deadLine = moment(initialDate).add(hasDays, 'd');
  const diffInMinutes = deadLine.diff(moment(), 'minutes');
  const days = Math.floor(diffInMinutes / (24 * 60));
  const hours = Math.floor((diffInMinutes - days * 24 * 60) / 60);
  const minutes = diffInMinutes - (days * 24 * 60 + hours * 60);
  // add leading zero to hours and minutes
  return {
    days,
    hours: `0${hours}`.slice(-2),
    minutes: `0${minutes}`.slice(-2),
  };
};

/**
 * converts user history data (array) to object with given structure:
 * key - history type
 * value - array with history entries for this type
 * @param  {array} _data - raw history data
 * @return {object}
 */
export const processHistoryData = (_data = []) => {
  const data = _data.filter(({ flow_type }) => flow_type === 'LEAD_FLOW');
  return Object.keys(CONTACT_HISTORY_TYPES).reduce((acc, key) => {
    acc[key] = data.filter(({ type }) => type === key);
    return acc;
  }, {});
};

/**
 * Used to build url for routing, F.E: 'PROM_AGENT' -> 'pa'
 */
export const getRolePrefix = role => {
  if (!role) return null;
  return role.includes('_')
    ? role
        .split('_')
        .map(part => part.charAt(0))
        .join('')
        .toLowerCase()
    : role.toLowerCase();
};

export const getEventStatusColor = status => {
  switch (status) {
    case PRESENTATION_STATUS.SUCCESS:
      return '#7ed321';
    case PRESENTATION_STATUS.CANCELED:
      return '#e91e63';
    case PRESENTATION_STATUS.REMEET:
      return '#ff9800';
    case PRESENTATION_STATUS.PLANNED:
      return '#4a90e2';
    default:
      return '#b4b4b4';
  }
};

export const getOrderStatusColor = status => {
  switch (status) {
    case ORDER_STATUS_KEYS.OPEN:
      return red[400];
    case ORDER_STATUS_KEYS.PENDING:
      return lime[400];
    case ORDER_STATUS_KEYS.CLOSED:
      return green[400];
    case ORDER_STATUS_KEYS.ON_HOLD:
      return deepOrange[400];
    default:
      return grey[400];
  }
};

export const getDeliveryStatusColor = status => {
  switch (status) {
    case ORDER_DELIVERY_STATUS_KEYS.DELIVERED:
      return green[400];
    case ORDER_DELIVERY_STATUS_KEYS.MAIN_PRODUCT_DELIVERED:
      return lime[400];
    case ORDER_DELIVERY_STATUS_KEYS.NOT_DELIVERED:
      return red[400];
    default:
      return grey[400];
  }
};

export const getPaymentStatusColor = status => {
  switch (status) {
    case ORDER_PAYMENT_STATUS_KEYS.OPEN:
      return red[400];
    case ORDER_PAYMENT_STATUS_KEYS.PENDING:
      return lime[400];
    case ORDER_PAYMENT_STATUS_KEYS.PAID:
      return green[400];
    default:
      return grey[400];
  }
};

export const getCommissionStatusColor = status => {
  switch (status) {
    case AGENT_COMMISSION_STATUS_KEYS.OPEN:
      return red[400];
    case AGENT_COMMISSION_STATUS_KEYS.PENDING:
      return lime[400];
    case AGENT_COMMISSION_STATUS_KEYS.PAID:
      return green[400];
    default:
      return grey[400];
  }
};

export const getCommissionReceiptStatusColor = status => {
  switch (status) {
    case AGENT_COMMISSION_RECEIPT_STATUS_KEYS.OPEN:
      return red[400];
    case AGENT_COMMISSION_RECEIPT_STATUS_KEYS.PAID:
      return green[400];
    default:
      return grey[400];
  }
};

export const getLatest = (list, key = 'date') => {
  if (!list || !list.length) return null;
  return list.sort((a, b) => new Date(b[key]) - new Date(a[key]))[0];
};

export const isElementVisible = el => {
  const rect = el.getBoundingClientRect(),
    vWidth = window.innerWidth || document.documentElement.clientWidth,
    vHeight = window.innerHeight || document.documentElement.clientHeight,
    efp = function (x, y) {
      return document.elementFromPoint(x, y);
    };

  // Return false if it's not in the viewport
  if (rect.right < 0 || rect.bottom < 0 || rect.left > vWidth || rect.top > vHeight) return false;

  // Return true if any of its four corners are visible
  return (
    el.contains(efp(rect.left, rect.top)) ||
    el.contains(efp(rect.right, rect.top)) ||
    el.contains(efp(rect.right, rect.bottom)) ||
    el.contains(efp(rect.left, rect.bottom))
  );
};

const ascendingComparator = (a, b, orderBy) => {
  return (
    isEmpty(a[orderBy]) - isEmpty(b[orderBy]) ||
    +(a[orderBy] > b[orderBy]) ||
    -(a[orderBy] < b[orderBy])
  );
};

const descendingComparator = (a, b, orderBy) => {
  return (
    isEmpty(a[orderBy]) - isEmpty(b[orderBy]) ||
    -(a[orderBy] > b[orderBy]) ||
    +(a[orderBy] < b[orderBy])
  );
};

export const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => ascendingComparator(a, b, orderBy);
};

export const renderNumberTitle = value => {
  if (!value || Number(value) === 0) return '0';

  return Number(value).toLocaleString('de-CH');
};

export const formatPrice = price => {
  if (isNaN(Number(price))) return '';

  return new Intl.NumberFormat('de-CH').format(price);
};

export const getEnvironment = () => {
  const isDOM = typeof window !== 'undefined' && window.document && window.document.documentElement;

  return isDOM ? 'browser' : 'server';
};
