import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import withStyles from '@mui/styles/withStyles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

import actions from '../core/store/actions/clients';
import { withTranslation } from '../i18n';
import { getRolePrefix } from '../core/utils';
import { Link } from '../server/routes';

const styles = () => ({
  title: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
});

class AircallEventDialog extends Component {
  handleClose = () => {
    const { dispatch } = this.props;
    dispatch(actions.setAircallEvent(null));
  };

  render() {
    const { classes, aircallEvent, currentUser, t } = this.props;

    const isOpen = !!aircallEvent;
    const clientsFound = aircallEvent?.clientsFound;
    const number = aircallEvent?.number;

    const rolePrefix = getRolePrefix(currentUser?.role?.code);
    const title =
      clientsFound > 1
        ? t('aircall_event_dialog.title.multiple_clients_found', { clients: clientsFound, number })
        : clientsFound === 1
        ? t('aircall_event_dialog.title.single_client_found', { number })
        : t('aircall_event_dialog.title.client_not_found', { number });

    return (
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.title} id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          {clientsFound > 0 && (
            <DialogContentText>{t('aircall_event_dialog.description')}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', p: 2, px: 3 }}>
          <Button variant="contained" onClick={this.handleClose} color="secondary" autoFocus>
            {t('aircall_event_dialog.btn_close')}
          </Button>
          {clientsFound > 0 && (
            <Link route={`${rolePrefix}-clients`} params={{ number }}>
              <a style={{ textDecoration: 'none' }} target="_blank">
                <Button variant="contained" onClick={this.handleClose} color="success" autoFocus>
                  {t('aircall_event_dialog.btn_yes')}
                </Button>
              </a>
            </Link>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default compose(
  withTranslation('common'),
  connect(({ clients: { aircallEvent }, auth: { currentUser } }) => ({
    aircallEvent,
    currentUser,
  })),
  withStyles(styles),
)(AircallEventDialog);
