import request from '../utils/request';

export default {
  Account: {
    getAccounts: () => request.get('/api/accounts'),
    updateAccount: (id, data) => request.put(`/api/account/${id}`, data),
  },

  Finances: {
    getOrders: query => request.get('/api/finances/orders', query),
    updateOrder: (order_id, data) => request.put(`/api/finances/order/${order_id}`, data),
    createOrderPayment: (order_id, data) =>
      request.post(`/api/finances/order-payment/${order_id}`, data),
    updateOrderPayment: (order_id, data) =>
      request.put(`/api/finances/order-payment/${order_id}`, data),
    deleteOrderPayment: id => request.del(`/api/finances/order-payment/${id}`),
    createOrderDelivery: (order_id, data) =>
      request.post(`/api/finances/order-delivery/${order_id}`, data),
    updateOrderDelivery: (order_id, data) =>
      request.put(`/api/finances/order-delivery/${order_id}`, data),
    deleteOrderDelivery: id => request.del(`/api/finances/order-delivery/${id}`),
    createOrderProduct: (order_id, data) =>
      request.post(`/api/finances/order-product/${order_id}`, data),
    updateOrderProduct: (order_id, data) =>
      request.put(`/api/finances/order-product/${order_id}`, data),
    deleteOrderProduct: id => request.del(`/api/finances/order-product/${id}`),
    getAgentCommissions: query => request.get('/api/finances/commissions', query),
    updateAgentCommission: (id, data) => request.put(`/api/finances/commission/${id}`, data),
    getReceipts: query => request.get('/api/finances/receipts', query),
    createReceipt: (agent_id, data) => request.post(`/api/finances/receipt/${agent_id}`, data),
    updateReceipt: (receipt_id, data) => request.put(`/api/finances/receipt/${receipt_id}`, data),
    deleteReceipt: receipt_id => request.del(`/api/finances/receipt/${receipt_id}`),
    rebuildReceipt: receipt_id => request.get(`/api/finances/rebuild-receipt/${receipt_id}`),
  },

  Mailer: {
    sendSignupMail: id => request.get(`/api/send-signup-mail/${id}`),
    sendInnerSignupMail: id => request.get(`/api/send-inner-signup-mail/${id}`),
    activateLicenseSubmitEmail: id => request.get(`/api/activate-license-submit/${id}`),
    // sendRecReminderEmail: id => request.get(`/api/send-rec-reminder-email/${id}`),
    getEmailRemindersList: data => request.get(`/api/email-reminders-list`, data),
    activateLicenseRequestEmail: data => request.post(`/api/activate-license-request`, data),
    updateEmailReminders: params => request.post(`/api/update-email-reminders`, params),
  },

  User: {
    createSubUser: (id, data) => request.post(`/api/sub-user/${id}`, data),
    getUser: id => request.get(`/api/user/${id}`),
    editUser: (id, data) => request.put(`/api/user/${id}`, data),
    deleteUser: id => request.del(`/api/user/${id}`),
    deleteSubUser: id => request.del(`/api/sub-user/${id}`),
    createUser: data => request.post('/api/user', data),
    checkEmail: email => request.get(`/api/user-check-email/${email}`),
    getUserRecommendationsCount: id => request.get(`/api/user-rec-count/${id}`),
    updateUserAccessibility: data => request.post('/api/user-accessibility', data),
    getUserInfo: id => request.get(`/api/user-info/${id}`),
    createUserInfo: (userId, data) => request.post(`/api/user-info/${userId}`, data),
    deleteUserInfo: (userId, data) => request.del(`/api/user-info/${userId}`, data),
    getUserProducts: userId => request.get(`/api/user-products/${userId}`),
    setupRefLink: userId => request.get(`/api/setup-ref-link/${userId}`),
    getUserPrimaryAgents: userId => request.get(`/api/user-primary-agents/${userId}`),
  },

  Users: {
    getPlainUsers: params => request.get('/api/plain-users', params),
    getUsers: params => request.get('/api/users', params),
    getUsersWithAccounts: params => request.get('/api/users-with-accounts', params),
    getSalesAgentUsers: params => request.get('/api/sales-agent-users', params),
    blockAccount: id => request.get(`/api/block-account/${id}`),
    unblockAccount: id => request.get(`/api/unblock-account/${id}`),
  },

  Presentation: {
    getPresentation: id => request.get(`/api/presentation/${id}`),
    createPresentation: data => request.post('/api/presentation', data),
    updatePresentation: (id, data) => request.put(`/api/presentation/${id}`, data),
    finishPresentation: (id, data) => request.put(`/api/finish-presentation/${id}`, data),
    deletePresentation: id => request.del(`/api/presentation/${id}`),
  },

  PresentationProducts: {
    createPresentationProducts: data => request.post('/api/presentation-products', data),
    getPresentationProducts: id =>
      id
        ? request.get(`/api/presentation-products/${id}`)
        : request.get('/api/presentation-products'),
  },

  Presentations: {
    getPresentations: params => request.get('/api/presentations', params),
    getPresentationsCounters: params => request.get('/api/presentations-counts/', params),
  },

  Recommendations: {
    // id - referer user id, if not provided - it will be the id of current user
    getRecommendations: (id, params) =>
      id
        ? request.get(`/api/recommendations/${id}`, params)
        : request.get('/api/recommendations', params),

    // OBSOLETE ??
    // addPotentialRecommendations: (data, referrerId) =>
    //   referrerId
    //     ? request.post(`/api/add-potential-rec/${referrerId}`, data)
    //     : request.post('/api/add-potential-rec', data),
  },

  ContactHistory: {
    add: (userId, data) => request.post(`/api/contact-history/${userId}`, data),
    edit: (userId, data) => request.put(`/api/contact-history/${userId}`, data),
  },

  Auth: {
    login: data => request.post('/auth/login', data),
    signup: data => request.post('/auth/signup', data),
    recoveryEmail: data => request.post('/auth/recovery-email', data),
    resetPassword: data => request.post('/auth/reset-password', data),
    logout: () => request.get('/auth/logout'),
    getCurrentUser: () => request.get('/auth/current'),
    editCurrentUser: data => request.put('/auth/edit-current', data),
    loginBy: user_id => request.get(`/auth/login-by/${user_id}`),
  },

  Public: {
    createReferralIndependentAgent: data => request.post('/api/referral-independent-agent', data),
    createReferralClient: data => request.post('/api/create-referral-client', data),
  },

  General: {
    getLocations: () => request.get('/api/locations'),
    getLocationsWithUsers: data => request.get('/api/locations-with-users', data),
    getSalesAgents: () => request.get('/api/sales-agents'),
    getCallAgents: () => request.get('/api/call-agents'),
    addLocation: data => request.post('/api/location', data),
    updateLocation: (locationId, data) => request.put(`/api/location/${locationId}`, data),
    deleteLocation: locationId => request.del(`/api/location/${locationId}`),
    getStatuses: () => request.get('/api/statuses'),
    getAppSettings: () => request.get('/api/global-settings'),
    updateAppSettings: data => request.post('/api/global-settings', data),
    omnicas: data => request.post('/api/omnicas', data),
    getTableSnapshot: table => request.get(`/api/general/snapshot/${table}`),
    updateTableSnapshot: (table, data) => request.put(`/api/general/snapshot/${table}`, data),
  },

  Products: {
    getProducts: params => request.get('/api/products', params),
  },

  Categories: {
    getCategories: () => request.get('/api/categories'),
  },

  Academy: {
    getSlides: category => request.get(`/api/slides/`),
    getVideos: category => request.get(`/api/videos/`),
    getAudios: category => request.get(`/api/audios/`),
    getDocs: category => request.get(`/api/docs/`),
  },

  Clients: {
    initiateCall: number => request.get(`/api/clients/initiate-call/${number}`),
    getClients: params => request.get(`/api/clients`, params),
    addContactHistory: (userId, data, params) =>
      request.post(`/api/clients/add-contact-history/${userId}`, data, params),
    editContactHistory: (userId, data, params) =>
      request.post(`/api/clients/edit-contact-history/${userId}`, data, params),
    editPresentationProducts: (presentationId, data) =>
      request.put(`/api/clients/edit-presentation-products/${presentationId}`, data),
    deletePresentationProduct: presentationProductId =>
      request.del(`/api/clients/delete-presentation-product/${presentationProductId}`),
    createService: (presentationId, data) =>
      request.post(`/api/clients/create-service/${presentationId}`, data),
    deleteService: serviceId => request.del(`/api/clients/delete-service/${serviceId}`),
    updateService: (serviceId, data) =>
      request.put(`/api/clients/update-service/${serviceId}`, data),
    createPresentation: data => request.post('/api/clients/create-presentation', data),
    deletePresentation: presentationId =>
      request.del(`/api/clients/delete-presentation/${presentationId}`),
    updatePresentation: (presentationId, data) =>
      request.put(`/api/clients/update-presentation/${presentationId}`, data),
    createUserInfo: (userId, data) => request.post(`/api/clients/create-user-info/${userId}`, data),
    deleteUserInfo: (userId, data) => request.post(`/api/clients/delete-user-info/${userId}`, data),
    updateUserInfo: (userInfoId, data) =>
      request.put(`/api/clients/update-user-info/${userInfoId}`, data),
    updateLinkedAgents: (agentId, data) =>
      agentId
        ? request.post(`/api/clients/update-linked-agents/${agentId}`, data)
        : request.post(`/api/clients/update-linked-agents`, data),
    getNestedTabsData: userId => request.get(`/api/clients/nested-tabs-data/${userId}`),
    getDuplicates: userId => request.get(`/api/clients/duplicates/${userId}`),
    getCohabitants: userId => request.get(`/api/clients/cohabitants/${userId}`),
    deleteClient: userId => request.del(`/api/clients/${userId}`),
    mergeDuplicate: data => request.post('/api/clients/duplicates', data),
    linkCohabitant: data => request.post('/api/clients/cohabitants', data),
    getBlockNeighbors: userId => request.get(`/api/clients/block-neighbors/${userId}`),
    linkBlockNeighbors: data => request.post(`/api/clients/block-neighbors`, data),
  },

  Statistic: {
    getFinanceStatistics: params => request.get('/api/stats/finances/', params),
    getSalesAgentData: params => request.get(`/api/stats/sales-agent`, params),
    getCallAgentData: params => request.get(`/api/stats/call-agent`, params),
    getDashboardData: params => request.get(`/api/stats/dashboard-statistic`, params),
    getPartnerData: params => request.get(`/api/stats/partner`, params),
    getLeadsData: params => request.get(`/api/stats/leads`, params),
  },

  Import: {
    prepareImport: params => request.get('/api/import/prepare-import', params),
    launchImport: (data, params) => request.post('/api/import/launch-import', data, params),
    uploadFromGDrive: data => request.post('/api/import/upload-from-gdrive', data),
    updateRecord: (data, params) => request.post('/api/import/update-record', data, params),
    poolOCRResult: () => request.get('/api/import/pool-ocr-result'),
    terminateRunningOCR: () => request.get('/api/import/terminate-running-ocr'),
    getOCRImports: () => request.get('/api/import/ocr-imports'),
    parseOCRImports: () => request.post('/api/import/ocr-imports'),
  },

  Partner: {
    getUsers: params => request.get('/api/partner-users', params),
  },

  Events: {
    getEventsUpcoming: params => request.get('/api/events-upcoming', params),
    getEventsHistory: params => request.get('/api/events-history', params),
  },

  Event: {
    getEvent: ({ eventType, id }) => request.get(`/api/event/${id}`, { eventType }),
    performService: (service_id, data) =>
      request.post(`/api/event/perform-service/${service_id}`, data),
  },

  Calendar: {
    createEvent: data => request.post(`/api/calendar-event`, data),
    updateEvent: data => request.put(`/api/calendar-event`, data),
    moveEvent: data => request.post(`/api/calendar-event-move`, data),
  },

  Planner: {
    getPreview: data => request.get(`/api/planner-preview`, data),
    getDetails: data => request.get(`/api/planner-details`, data),
    getBlockedDatesForMonth: data => request.get(`/api/planner-blocked-dates`, data),
  },

  AddressValidation: {
    processAddress: data => request.post(`/api/address-validation`, data),
  },

  License: {
    getAgentLicense: agentId => request.get(`/api/license/get-agent-license/${agentId || ''}`),
    createCheckout: data => request.post(`/api/license/create-checkout`, data),
  },
};
