import keyMirror from 'key-mirror';

export const actionTypes = keyMirror({
  SET_ACTIVE_ITEM: null,
  SET_CONTENT_CATEGORY: null,
  SET_SELECTED_AUDIOS: null,
  SET_SEARCH: null,
  SET_SEARCH_ACTION: null,
  SUBPAGE_CLOSE: null,
  GET_CONTENT_DATA_ASYNC_START: null,
  GET_CONTENT_DATA_ASYNC_SUCCESS: null,
  GET_CONTENT_DATA_ASYNC_ERROR: null,
  GET_SLIDES_ASYNC_START: null,
  GET_SLIDES_ASYNC_SUCCESS: null,
  GET_SLIDES_ASYNC_ERROR: null,
  GET_VIDEOS_ASYNC_START: null,
  GET_VIDEOS_ASYNC_SUCCESS: null,
  GET_VIDEOS_ASYNC_ERROR: null,
  GET_AUDIOS_ASYNC_START: null,
  GET_AUDIOS_ASYNC_SUCCESS: null,
  GET_AUDIOS_ASYNC_ERROR: null,
  GET_DOCS_ASYNC_START: null,
  GET_DOCS_ASYNC_SUCCESS: null,
  GET_DOCS_ASYNC_ERROR: null,
});

export default {
  setActiveItem: data => dispatch => {
    dispatch({ type: actionTypes.SET_ACTIVE_ITEM, payload: data });
  },
  setContentCategory: category => dispatch => {
    dispatch({ type: actionTypes.SET_CONTENT_CATEGORY, payload: category });
  },
  setSelectedAudios: id => dispatch => {
    dispatch({ type: actionTypes.SET_SELECTED_AUDIOS, payload: id });
  },
  setSearch: value => dispatch => {
    dispatch({ type: actionTypes.SET_SEARCH, payload: value });
  },
  setSearchAction: () => dispatch => {
    dispatch({ type: actionTypes.SET_SEARCH_ACTION });
  },
  subPageClose: () => dispatch => {
    dispatch({ type: actionTypes.SUBPAGE_CLOSE });
  },
  getContentData: category => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_CONTENT_DATA_ASYNC_START });
    return api.Academy.getSlides(category).then(
      payload => {
        dispatch({ type: actionTypes.GET_SLIDES_ASYNC_SUCCESS, payload });
        return api.Academy.getVideos(category).then(
          payload => {
            dispatch({ type: actionTypes.GET_VIDEOS_ASYNC_SUCCESS, payload });
            return api.Academy.getAudios(category).then(
              payload => {
                dispatch({ type: actionTypes.GET_AUDIOS_ASYNC_SUCCESS, payload });
                return api.Academy.getDocs(category).then(
                  payload => {
                    dispatch({ type: actionTypes.GET_DOCS_ASYNC_SUCCESS, payload });
                    dispatch({ type: actionTypes.GET_CONTENT_DATA_ASYNC_SUCCESS });
                  },
                  error => dispatch({ type: actionTypes.GET_CONTENT_DATA_ASYNC_ERROR, error }),
                );
              },
              error => dispatch({ type: actionTypes.GET_CONTENT_DATA_ASYNC_ERROR, error }),
            );
          },
          error => dispatch({ type: actionTypes.GET_CONTENT_DATA_ASYNC_ERROR, error }),
        );
      },
      error => dispatch({ type: actionTypes.GET_CONTENT_DATA_ASYNC_ERROR, error }),
    );
  },
};
