const { isArray, isDate, isObject, forEach } = require('lodash');

function encode(val) {
  return encodeURIComponent(val)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+')
    .replace(/%5B/gi, '[')
    .replace(/%5D/gi, ']');
}

/**
 * Build a URL by appending params to the end
 *
 * @param {string} url The base of the url (e.g., http://www.google.com)
 * @param {object} [params] The params to be appended
 * @returns {string} The formatted url
 */
const buildURL = (url, params) => {
  /* eslint no-param-reassign:0 */
  if (!params) {
    return url;
  }

  const parts = [];

  forEach(params, (val, key) => {
    if (val === null || typeof val === 'undefined') {
      return;
    }

    if (isArray(val)) {
      key = `${key}[]`;
    } else {
      val = [val];
    }

    forEach(val, v => {
      if (isDate(v)) {
        v = v.toISOString();
      } else if (isObject(v)) {
        v = JSON.stringify(v);
      }
      parts.push(`${encode(key)}=${encode(v)}`);
    });
  });

  const serializedParams = parts.join('&');

  if (serializedParams) {
    const hashmarkIndex = url.indexOf('#');
    if (hashmarkIndex !== -1) {
      url = url.slice(0, hashmarkIndex);
    }

    url += (url.indexOf('?') === -1 ? '?' : '&') + serializedParams;
  }

  return url;
};

module.exports = buildURL;
